import signupBannerImagePath from '../img/signup-es.png';
import loginBannerImagePath from '../img/login-es.png';

// Basic constants
const year = new Date().getFullYear();
const urlDopplerLegacy = process.env.REACT_APP_DOPPLER_LEGACY_URL;
const urlReports = process.env.REACT_APP_REPORTS_URL;
const urlSite = `https://www.fromdoppler.com`;
const urlHelp = `https://help.fromdoppler.com/es`;
const urlShopify = process.env.REACT_APP_SHOPIFY_URL;
const urlConversations = process.env.REACT_APP_DOPPLER_CONVERSATIONS_URL;
//const dopplerUiLibraryVersion = process.env.REACT_APP_DOPPLER_UI_LIBRARY_VERSION;

// Common URLs
const urlHelpAdvancedReports = `${urlHelp}/reportes-avanzados`;
const urlPrivacy = `${urlSite}/legal/privacidad`;
const urlPrivacyFromSignup = `${urlPrivacy + window.location.search}`;
const urlPrivacyFromSignup_HTMLEncoded = `${urlPrivacy + window.location.search}`;
const urlPrivacyFromLogin = `${urlPrivacy + window.location.search}`;
const urlPrivacyFromForgot = `${urlPrivacy}`;
const mailtoSupport = `mailto:soporte@fromdoppler.com`;
const mailtoAdmin = `mailto:administracion@fromdoppler.com`;
const subjectBlockedAccountNoPay = `?subject=Cuenta%20suspendida%20por%20falta%20de%20pago%20-%20Login`;
const subjectCanceledAccountNoPay = `?subject=Cuenta%20cancelada%20por%20falta%20de%20pago%20-%20Login`;
const subjectCanceledAccountOtherReason = `?subject=Cuenta%20cancelada%20-%20Login`;
const subjectBlockedAccountInvalidPassword = `?subject=Cuenta%20bloqueada%20por%20intentos%20fallidos%20-%20Login`;
const urlControlPanel = `${urlDopplerLegacy}/ControlPanel`;
const urlPlanSelection = `/plan-selection-deprecated`;
const urlPlanSelectionNew = `/plan-selection/premium/by-contacts`;
const urlSiteTracking = `${urlControlPanel}/CampaignsPreferences/SiteTrackingSettings`;
const urlSiteFromSignup = `${urlSite}/`;
const urlSiteFromLogin = `${urlSite}/`;
const urlSiteFromForgot = `${urlSite}/`;
const urlControlPanelMain = `${urlControlPanel}/ControlPanel`;
const urlMasterSubscriber = `${urlDopplerLegacy}/Lists/MasterSubscriber/`;
const urlCreateSubscriberList = `${urlDopplerLegacy}/Lists/List`;
const urlDraft = `${urlDopplerLegacy}`;
const urlUpgradePlan = `${urlDopplerLegacy}/ControlPanel/AccountPreferences/UpgradeAccount`;
const urlPreUpgradePlan = `${urlDopplerLegacy}/ControlPanel/AccountPreferences/PreUpgrade`;
const urlDkim = `${urlDopplerLegacy}/ControlPanel/AdvancedPreferences/Dkim`;

const messages_es = {
  addons: {
    carousel: {
      slice_1_description: `Mejora la experiencia con tus clientes y genera más Leads con <Bold>Web Chatbot, Social Media Chatbot y Whatsapp Marketing.</Bold>`,
      slice_1_title: `Gestiona la comunicación con tus usuarios a través de una única plataforma`,

      slice_2_description: `Elige la solución ideal <Bold>para captar más clientes y disminuir los costos</Bold> de contratar múltiples herramientas.`,
      slice_2_title: `Potencia tus ventas con Landing Pages optimizadas`,

      slice_3_description: `Implementa Widgets y Pop-ups para recomendar productos de interés del cliente, brindar ofertas personalizadas, incentivar a retomar compras y motivar la venta de artículos relacionados.`,
      slice_3_title: `Mejora la experiencia de compra con Doppler OnSite`,

      slice_4_description: `Otorga permisos a las personas de tu equipo o de otro, <Bold>para acceder a las diferentes herramientas de la plataforma.</Bold>`,
      slice_4_title: `Optimiza tu equipo de trabajo asignando roles y colaboradores en tu cuenta`,
    },
  },
  agencies: {
    feature_access: 'Acceso al panel de Client Manager para gestionar las cuentas de tus clientes.',
    feature_admin: 'Administración de perfiles de usuario y permisos de acceso para tus clientes.',
    feature_consultancy: 'Asesoramiento exclusivo para gestionar múltiples cuentas.',
    feature_custom: 'Personalización con el logotipo de tu empresa.',
    feature_reports: 'Reportes Avanzados sobre el desempeño de las Campañas.',
    features_title: '¿Qué incluye el Plan para Agencias?',
    form_legend: 'Completa este Formulario y te contactaremos en breve.',
    label_volume: 'Volumen de Emails por mes:',
    submit: 'Solicitar demo',
    submitted: 'Continuar en Doppler',
    subtitle_MD: `Conoce todo lo que el Email Marketing puede hacer por tu Agencia con una **demo personalizada**.`,
    success_msg: '¡Excelente! Nos pondremos en contacto contigo.',
    title: 'Plan para Agencias',
    volume_0: 'Menos de 500k',
    volume_10m: 'Más de 10m',
    volume_1m: 'Entre 1m y 10m',
    volume_500: 'Entre 500k y 1m',
    volume_do_not_know: 'No lo sé',
  },
  big_query: {
    add_google_account: `Agregar cuenta de Google.`,
    add_permission_google_account: `Agregar permiso para cuentas de Google.`,
    free_alt_image: `big query`,
    free_btn_redirect: `Ver planes plus`,
    free_text_data_studio_MD: `[Configura el tablero prediseñado de Google Looker Studio en unos pocos pasos](https://developers.google.com/datastudio) para analizar reportes detallados sobre tus campañas,
    tus suscriptores y otros datos de tu cuenta en un tablero personalizable`,
    free_text_strong: `Potencia tus Campañas con reportes de Google Looker Studio, Tableau, Power BI y otras herramientas de Data Analytics.`,
    free_text_summary: `Accede a reportes detallados para analizar el rendimiento de tus Campañas de Email & Automation Marketing. Con esta integración podrás: `,
    free_title: `Conecta Doppler con BigQuery`,
    free_ul_item_filter: `Aplicar diferentes filtros para enfocar el análisis en uno o varios aspectos de tu estrategia.`,
    free_ul_item_insights: `Trabajar desde un tablero personalizado y con vistas editables.`,
    free_ul_item_strategy: `Obtener información valiosa para tu estrategia.`,
    plus_beginning: `Inicio`,
    plus_big_query: `BigQuery`,
    plus_body_step_one_MD: `Otorgar acceso a BigQuery. [HELP](${urlHelp}/integrar-doppler-bigquery#permisos)`,
    plus_body_step_two_MD: `Crear reportes. [HELP](${urlHelp}/integrar-doppler-bigquery#crear-reportes)`,
    plus_configuration: `Configuración`,
    plus_control_panel: `Panel de Control`,
    plus_header_MD: `Accede a reportes detallados para analizar el rendimiento de tus Campañas de Email & Automation Marketing.`,
    plus_message_error: `Error guardando los cambios.`,
    plus_message_remember: `Recuerda guardar los cambios.`,
    plus_message_saved: `Cambios guardados con éxito.`,
    plus_paragraph_two: `Google BigQuery`,
    plus_step_one: `Primer paso`,
    plus_step_one_paragraph: `Los usuarios tendrán acceso a la información de tu cuenta almacenada en BigQuery. Recibirán una notificación por correo electrónico informando que les has dado dichos accesos.`,
    plus_step_one_paragraph_MD: `**¡Psst!** Solo puedes otorgar acceso a **correos electrónicos de Google**, tanto de Gmail como de dominios empresariales.`,
    plus_step_two: `Segundo paso`,
    plus_step_two_paragraph_MD: `Conecta Doppler y BigQuery para visualizar reportes detallados en tu herramienta de Data Analytics. Doppler te proveerá un **tablero prediseñado de Google Looker Studio** que
    podrás configurar en simples pasos.`,
    plus_title: `Conecta Doppler con BigQuery`,
    upgrade_plan_url: `${urlUpgradePlan}`,
    url_legacy_control_panel: `${urlControlPanelMain}`,
    url_legacy_doppler: `${urlDopplerLegacy}`,
  },
  buy_process: {
    billing_type_1: `Facturación mensual`,
    billing_type_12: `Facturación anual`,
    billing_type_3: `Facturación trimestral`,
    billing_type_6: `Facturación semestral`,
    buy_now_title: `Comprar ahora`,
    chat_plan_title: `Plan conversaciones`,
    continue: 'Continuar',
    difference_months_to_pay: 'Diferencia a abonar:',
    discount_for_payment_paid: 'Saldo a favor:',
    discount_half_yearly: 'Semestral',
    discount_monthly: 'Mensual',
    discount_percentage: `Ahorro {discountPercentage}%`,
    discount_quarterly: 'Trimestral',
    discount_yearly: 'Anual',
    feature_item_chat_plan: `Incluye hasta <Strong>{units} conversaciones</Strong>`,
    feature_item_discount_advanced_pay: `Descuento pago adelantado {months}/meses`,
    feature_item_discount_monthly: `Descuento Código Promocional {months}/meses`,
    feature_item_discount_monthly_argentina: `Descuento Exclusivo Argentina {months}/meses`,
    feature_item_extra_credits: `Incluye {units} créditos extra`,
    'feature_item_monthly-deliveries': `Incluye hasta <Strong>{units} envíos</Strong>`,
    feature_item_onsite_plan: `Incluye hasta <Strong>{units} impresiones</Strong>`,
    feature_item_prepaid: `Incluye hasta <Strong>{units} créditos</Strong>`,
    feature_item_subscribers: `Incluye hasta <Strong>{units} contactos</Strong>`,
    marketing_plan_title: `Plan marketing`,
    min_monthly_plan_price: `<P>Desde <Strong>US$ {price}*/mes</Strong></P>`,
    min_single_plan_price: `<P>Desde <Strong>US$ {price}*/único</Strong></P>`,
    month_with_plural: `{months, plural, one {# mes}other {# meses} }`,
    months_to_hire: 'Meses a contratar:',
    months_to_pay: `Meses a abonar: {months} meses`,
    onsite_plan_title: `Plan OnSite`,
    payment_frequency: `Frecuencia de pago`,
    plan_selection: {
      breadcumb_plan_text: `Plan marketing`,
      breadcumb_plan_url: `${urlPlanSelectionNew}`,
      plan_subtitle_description_MD: `Elige la solución que más se adecúe a tu objetivo de Campaña. Selecciona tu plan, frecuencia y visualiza tu compra.`,
      plan_title: `Elige tu plan de marketing`,
      plan_type: 'Tipo de plan',
      plan_type_monthly_deliveries_info: 'Envía una gran cantidad de Emails a un bajo costo unitario.',
      plan_type_monthly_deliveries_label: 'Envíos',
      plan_type_prepaid_info: 'Envía Emails a partir de créditos acumulables y sin vencimiento. ',
      plan_type_prepaid_label: 'Créditos',
      plan_type_subscribers_info: 'Envía Emails ilimitados en base a tu cantidad de Contactos.',
      plan_type_subscribers_label: 'Contactos',
      plan_type_units: `¿Cuántos {units} deseas agregar a tu plan?`,
    },
    promocode: {
      apply_btn: `Aplicar`,
      discount_for_admin: 'Descuento general: <Strong>{percentage}</Strong>',
      dropdown_title_close: `¿Tienes un código promocional?`,
      dropdown_title_open: `Ingresa tu código promocional`,
      extra_credits_label: `Créditos Extras:`,
      is_valid_to: `Durante {months} meses`,
      label_month: `mes`,
      valid_until_label: `Vigentes hasta agotarse la existencia`,
    },
    shopping_cart: {
      price_without_taxes: `*El precio no incluye impuestos.`,
      promotional_code: `¿Tienes un código promocional?`,
      renewal_description: `Suscripción con renovación automática, puedes cancelarla cuando lo desees.`,
      save_percentage: `Ahorro {percentage}`,
      title: `Resumen de compra`,
    },
    stepper: {
      conversation_plan_step: 'Plan conversaciones',
      email_marketing_plan_step: 'Plan marketing',
      enjoy_doppler_step: 'Disfruta Doppler',
      finalize_purchase_step: 'Finaliza tu compra',
      landings_plan_step: 'Plan Landings',
      onsite_plan_step: 'Plan OnSite',
    },
    subscriptions_title: `Suscripciones`,
    upcoming_bills: {
      chat_plan_subtitle: `Planes de chat`,
      landing_pack_subtitle: `Packs de Landing Pages`,
      marketing_and_chat_plan_subtitle: `Planes de marketing y chat`,
      marketing_plan_subtitle: `Plan marketing`,
      onsite_plan_subtitle: `Plan de OnSite`,
      title: `Próximas facturas`,
    },
  },
  change_plan: {
    all_of_plan: `Todo lo de {plan} más:`,
    ask_demo: 'Solicitar demo',
    banner_exclusive_features_description: `¿Necesitas <Bold>asesoría y acompañamiento exclusivo, IPs dedicadas o funcionalidades extras</Bold>? Podemos diseñar un Plan a la medida de tu negocio.`,
    banner_exclusive_features_title: 'Servicios adicionales',
    big_data_tooltip: 'Funcionalidad Big Data',
    calculate_price: 'Calcular Precio',
    card_agencies_description: 'Gestiona múltiples cuentas desde un solo lugar.',
    card_agencies_title: 'Agencias',
    card_free_description: 'Envía Campañas ilimitadas hasta 500 Contactos, sin contratos ni tarjetas de crédito.',
    card_free_title: 'GRATUITO',
    card_plus_description: 'Aumenta la efectividad de tus Campañas con Big Data. ¡Contarás con atención personalizada!',
    card_plus_title: 'Plus',
    card_standard_description: 'Suma funcionalidades a tu estrategia eligiendo el tipo de Plan.',
    card_standard_title: 'Avanzado',
    current_plan: 'Plan Actual',
    features_HTML_agencies: `
    <star>Acceso al panel de Client Manager para gestionar múltiples cuentas.</star>
    <star>Configuración de niveles de permiso y accesos para cada cuenta o cliente.</star>
    <option>Personalización con el logo de tu empresa.</option>
    <option>Reportes Avanzados sobre el desempeño de las Campañas de cada cliente.</option>
    <option>Asesoramiento exclusivo para la gestión del Client Manager.</option>`,
    features_HTML_free: `
    <option>Acceso a la API de Doppler.</option>
    <option>Formularios de Suscripción Clásicos y Pop-up.</option>
    <option>Campañas clásicas y sociales ilimitadas.</option>
    <option>Email Automation básico.</option>
    <option>Reportes en tiempo real.</option>
    <option>Atención al Cliente por múltiples canales.</option>
    <option>Integraciones con otras plataformas.</option>
    <option>Emojis y Campos Personalizados en Asuntos.</option>
    <newOption>Guía de buenas prácticas en Asuntos.</newOption>
    <newOption>Listado de Asuntos más efectivos.</newOption>
    <optionComingSoon>Limpieza inteligente de Contactos.</optionComingSoon>`,
    features_HTML_plus: `
    <star>Hasta 3 Reportes personalizados bonificados.</star>
    <bigData>Integración con BigQuery y herramientas de Data Analytics.</bigData>
    <bigData>Tablero de Google Looker Studio 100% personalizable.</bigData>
    <bigData>Predicción de eficacia en Asuntos.</bigData>
    <bigData>Envío Inteligente en el mejor día y horario para cada Contacto.</bigData>
    <newOption>Política de Contacto.</newOption>
    <newOption>Detener Envío en progreso.</newOption>
    <optionComingSoon>Creación automática de Segmentos.</optionComingSoon>
    <optionComingSoon>Corrección automática de direcciones de Email incorrectas.</optionComingSoon>
    <optionComingSoon>Predicción de spam.</optionComingSoon>
    <optionComingSoon>Email Automation de Re Engagement.</optionComingSoon>
    <optionComingSoon>Plantillas Inteligentes.</optionComingSoon>`,
    features_HTML_standard: `
    <star>Email Automation avanzado para E-commerce.</star>
    <star>Análisis de Comportamiento en Sitio.</star>
    <star>Reportes Avanzados.</star>
    <star>Campañas SMS.</star>
    <star>Envío Inteligente en el mejor horario para cada Contacto.</star>
    <option>Campañas Test A/B.</option>
    <option>Campañas 100% personalizadas sin la firma de Doppler.</option>
    <optionComingSoon>Botón de WhatsApp para Sitios Web.</optionComingSoon>`,
    features_title_agencies: 'Funcionalidades del Plan Plus, más:',
    features_title_plus: 'Funcionalidades del Plan Avanzado, más:',
    features_title_standard: 'Funcionalidades del Plan Gratuito, más:',
    hide_features: 'Ocultar Funcionalidades',
    increase_action_monthly_deliveries: 'Aumentar Envíos',
    increase_action_prepaid: 'Comprar Créditos',
    increase_action_subscribers: 'Aumentar Contactos',
    link_exclusive_features: 'Solicitar',
    new_label: 'Nuevo',
    option_coming: 'Próximamente',
    per_month: 'por mes',
    recommended: 'Recomendado',
    show_features: 'Ver todas las Funcionalidades',
    since: 'Desde',
    title: 'Planes que acompañan cada etapa de tu negocio',
    until_x_subscribers: `Hasta {subscribers} Contactos.`,
  },
  chat_selection: {
    banner_for_conversations: `¿Necesitas más Conversaciones? <Link>Escríbenos</Link>.`,
    expiration_free_plan_message: '*Recuerda que al contratar un plan de Conversaciones, finalizara de manera automática la versión de prueba de tres meses.',
    how_many_conversations_need_message: '¿Cuántas Conversaciones necesitas por mes?',
    more_option_tickmark_message: 'Más',
    plan_benefits: {
      additional_costs: {
        additional_agent_message: 'Agente adicional.',
        additional_channel_message: 'Canal adicional.',
        additional_conversation_message: 'Conversación adicional.',
        additional_costs_message: 'Costos Adicionales',
        extra_costs_message: 'Costos extras',
        from: 'Desde',
        legend_1_message: '*Podrás incluir Adicionales desde <bold>Mi Plan</bold> una vez superados los valores establecidos por tu servicio contratado.',
      },
      collaborative_care_message: 'Atención colaborativa',
      default_messages_message: 'Mensajes predeterminados',
      facebook_messenger_chat_message: 'Chat en Facebook Messenger',
      included_all_plans_message: 'Incluido en todos los planes',
      included_paid_plans_greater_250_conversations_message: 'Incluido en los planes pagos mayores a 250 conversaciones',
      included_paid_plans_message: 'Incluido en todos los planes pagos',
      instagram_chat_message: 'Chat en Instagram',
      lead_generator_message: 'Generador de Leads',
      messages_by_conversation_tree_message: 'Mensajes por árbol de Conversaciones',
      messages_tracking_message: 'Seguimiento de mensajes',
      support_message: 'Soporte',
      tags_message: 'Etiquetas',
      title: '¿Qué beneficios incluye cada plan?',
      website_chat_message: 'Chat en Sitio Web',
      whatsapp_business_api_chat_message: 'Chat en WhatsApp Business API',
      whatsapp_business_api_send_messages_message: 'Campaña de WhatsApp',
    },
    plan_chat_info: {
      legend: 'Selecciona la cantidad de <bold>Conversaciones</bold> que tu negocio necesita para complementar e impulsar tu Estrategia de Marketing.',
      section_1: {
        legend: 'Gestiona la comunicación de diferentes canales a través de una única plataforma.',
        title: 'Experiencia optimizada',
      },
      section_2: {
        legend: 'Comunícate con tus clientes al instante y no pierdas oportunidades de ventas.',
        title: 'Estrategia Omnicanal',
      },
    },
    selected_plan_chat: {
      add_to_cart_button: 'Agregar al carrito',
      agent_with_plural: `{agents, plural, one {# Agente}other {# Agentes} }`,
      channel_with_plural: `{channels, plural, one {# Canal}other {# Canales} }`,
      conversations_plan_message: 'Plan Conversaciones*',
      includes_until_conversations_message: 'Incluye hasta {conversations} Conversaciones',
      month_message: 'mes',
      no_chat_plan_selected_message: 'No se ha seleccionado un plan de conversación',
      remove_from_cart_button: 'Remover del carrito',
    },
    title: '¿Quieres agregas un plan de chat?',
  },
  checkoutProcessForm: {
    billing_information_address: 'Dirección fiscal',
    billing_information_answer_question: 'Ingresa la respuesta a la pregunta elegida:',
    billing_information_choose_question: 'Elige la pregunta que prefieras:',
    billing_information_city: 'Ciudad',
    billing_information_country: 'País',
    billing_information_firstname: 'Nombre',
    billing_information_lastname: 'Apellido',
    billing_information_phone: 'Teléfono',
    billing_information_province: 'Estado / Provincia:',
    billing_information_same_address: 'Es la misma que la Información de Contacto.',
    billing_information_title: `Información de Facturación`,
    billing_information_zip_code: 'Código postal',
    button_back: 'Volver',
    contact_information_address: 'Dirección',
    contact_information_city: 'Ciudad',
    contact_information_company: 'Empresa',
    contact_information_country: 'País',
    contact_information_firstname: 'Nombre',
    contact_information_industry: 'Industria',
    contact_information_lastname: 'Apellido',
    contact_information_phone: 'Teléfono',
    contact_information_province: 'Estado / Provincia',
    contact_information_security_question: 'Pregunta de seguridad',
    contact_information_security_response: 'Respuesta',
    contact_information_title: `Información de Contacto`,
    contact_information_zip_code: 'Código postal',
    discount_half_yearly: '6 meses',
    discount_monthly: '1 mes',
    discount_quarterly: '3 meses',
    discount_subscription_half_yearly: 'Posees un tipo de renovación Semestral',
    discount_subscription_monthly: 'Posees un tipo de renovación Mensual',
    discount_subscription_quarterly: 'Posees un tipo de renovación Trimestral',
    discount_subscription_subtitle: 'SOBRE EL PRECIO DEL PLAN',
    discount_subscription_yearly: 'Posees un tipo de renovación Anual',
    discount_title: 'Tipo de renovación',
    discount_yearly: '12 meses',
    edit: 'Editar',
    empty_option_select: `Selecciona una opción`,
    payment_method: {
      applied_promocode_tooltip: 'Ya tienes un código de descuento aplicado.',
      automatic_debit: 'Débito automático',
      availabled_credit_cards_legend: 'Usa una tarjeta internacional de <bold>Visa, Mastercard o American Express</bold>.',
      availabled_credit_cards_legend_alt_text: 'Tarjetas internacionales Visa, Mastercard, American Express',
      bank_account: 'Últimos 4 dígitos de tu cuenta',
      bank_name: 'Nombre del banco',
      business_name: 'Razón social',
      cbu: 'CBU',
      cfdi: 'Uso de CFDI',
      considerations: 'Consideraciones:',
      considerations_automatic_debit_note_1: `La factura por tu abono a Doppler será debitada automáticamente de tu cuenta bancaria entre los días 2 y 5 de cada mes,
        lo cual te permitirá tener el control de los pagos directamente desde el resumen de cuenta del banco.`,
      considerations_credit_card_note_1: 'Emitiremos una <bold>factura en dólares desde Estados Unidos</bold>, donde se encuentra nuestra filial Doppler LLC.',
      considerations_credit_card_note_2: 'El <bold>monto a pagar</bold> corresponde al precio del Plan contratado y está <bold>sujeto a impuestos aplicables</bold> en cada país.',
      considerations_mercado_pago_note_1:
        'Emitiremos la factura a <bold>Consumidor Final</bold> en <bold>pesos argentinos</bold>, según el tipo de cambio vendedor del Banco de la Nación Argentina al cierre del día anterior a la emisión de la misma.',
      considerations_mercado_pago_note_2: 'Procesaremos el pago mensualmente a través de la plataforma Mercado Pago.',
      considerations_mercado_pago_note_3: 'El cargo aparecerá en el resumen de tu Tarjeta de Crédito como <bold>MERPAGO*DOPPLER.</bold>',
      consumer_type: 'Consumidor',
      credit_card: 'Número de tarjeta',
      credit_card_option: 'Tarjeta de crédito',
      dni: 'DNI',
      error: '¡Ouch! Esta Tarjeta de Crédito no permite cobros por parte de Doppler LLC. Comunícate con la empresa emisora para autorizarlos.',
      expiration_date: 'Fecha de vencimiento',
      first_data_error: {
        card_volume_exceeded: '¡Ouch! Superaste el límite diario de intentos con tu tarjeta. Por favor aguarda 24hs para intentar nuevamente o elige otra tarjeta.',
        declined: '¡Ouch! Transacción no aprobada. Comunicate con el emisor de tu tarjeta.',
        doNotHonor: '¡Ouch! Transacción no aprobada. Comunicate con el emisor de tu tarjeta.',
        insufficient_funds: '¡Ouch! Tarjeta con fondos insuficientes. Prueba con otra tarjeta.',
        invalid_credit_card_number: '¡Ouch! Tarjeta invalida. Inténtalo nuevamente o prueba con otra tarjeta.',
        invalid_expiration_date: '¡Ouch! Tarjeta invalida. Inténtalo nuevamente o prueba con otra tarjeta.',
        suspected_fraud: '¡Ouch! Transacción no aprobada. Comunicate con el emisor de tu tarjeta.',
      },
      first_last_name: 'Nombre y Apellido',
      holder_name: 'Nombre y apellido',
      identification_type_colombia: 'NIT',
      mercado_pago: 'Mercado Pago',
      mercado_pago_error: {
        invalid_security_code: '¡Ouch! Tarjeta invalida. Inténtalo nuevamente o prueba con otra tarjeta.',
      },
      payment_way: 'Forma de pago',
      placeholder_holder_name: 'tu nombre aquí',
      responsable_iva: 'Responsable IVA',
      responsable_iva_no: 'No',
      responsable_iva_yes: 'Si',
      security_code: 'Código de seguridad',
      tax_certificate: 'Constancia de situación fiscal',
      tax_regime: 'Regimen fiscal',
      title: 'Método de Pago',
      transfer: 'Transferencia',
      valid_thru: 'válido hasta',
      warning_mex_transfer: 'Los datos que ingreses deben coincidir con la información que aparece en tu constancia de situación fiscal.',
      withholding_agent: 'No soy agente de retención',
    },
    purchase_summary: {
      add_recipient_placeholder: 'Agregar destinatario',
      buy_button: 'COMPRAR',
      credits_for_promocode: 'Créditos extra:',
      difference_months_to_pay: 'Diferencia de meses a abonar:',
      discount_for_admin: 'Descuento general:',
      discount_for_payment_paid: 'Saldo a favor:',
      discount_for_prepayment: 'Descuento por pago adelantado:',
      discount_for_promocode: 'Descuento código:',
      edit_add_recipients_button: 'Editar o agregar destinatarios',
      edit_add_recipients_confirmation_button: 'Confirmar edición',
      error_message: 'No pudimos procesar la operación. Elige otro método de pago o inténtalo más tarde.',
      error_only_supports_upselling_message: 'No pudimos procesar la operación. Este es el Plan que tienes actualmente. Modifícalo para concretar la compra.',
      explanatory_legend: 'La renovación es automática y puedes cancelarla cuando quieras. El precio del Plan puede estar sujeto a impuestos.',
      explanatory_legend_by_credits: 'El precio del Plan puede estar sujeto a impuestos, de acuerdo a la categoría impositiva. Estos estarán detallados en tu factura.',
      header: 'Resumen de compra',
      month_with_plural: `{monthsCount, plural, one {# mes}other {# meses} }`,
      months_to_hire: 'Meses a contratar:',
      months_to_pay: 'Meses a abonar:',
      plan_premium_title: 'Plan PREMIUM',
      plan_type_monthly_deliveries: 'por Envíos',
      plan_type_monthly_deliveries_label: 'Envíos:',
      plan_type_prepaid: 'por Créditos',
      plan_type_prepaid_label: 'Créditos:',
      plan_type_subscribers: 'por Contactos',
      plan_type_subscribers_label: 'Contactos:',
      positive_balance_message: 'Tienes un saldo a favor de US$ {positiveBalance} por los créditos no utilizados de tu última compra. Lo descontaremos de tus próximas facturas.',
      promocode_error_message: 'Código de descuento inválido',
      promocode_header: 'Código de descuento',
      promocode_label: 'Código de descuento',
      promocode_legend: 'Ingrésalo aquí',
      promocode_placeholder: 'Ingrésalo aquí',
      promocode_success_message: '¡Código de descuento aplicado!',
      promocode_tooltip: 'Ya tienes un descuento aplicado por pago adelantado.',
      promocode_validate_button: 'Validar',
      secure_payment_message: 'Pago seguro',
      send_invoice_email_message: 'Enviaremos tu factura por mail a:',
      success_message: '¡Operación procesada con éxito!',
      taxes_excluded: '(Impuestos no incluídos)',
      to_pay_from_next_month_legend: 'A pagar en tu próxima factura',
      total: 'TOTAL:',
      transfer_explanatory_legend: 'El precio del Plan puede estar sujeto a impuestos, de acuerdo a tu categoría impositiva. Los encontrarás detallados en la factura.',
      transfer_explanatory_legend2: 'La renovación es automática y puedes cancelarla cuando quieras.',
      upgrade_plan_legend: 'Por la fecha de realización del cambio del plan no se le adicionará un costo adicional pero el primer día hábil del mes siguiente se le cobrará el total del plan.',
      your_next_billing_legend: 'Tu próxima factura será de',
    },
    save_continue: 'Guardar y continuar',
    title: `Proceso de pago`,
  },
  checkoutProcessSuccess: {
    chat_plan_acquired_conversations: 'Conversaciones adquiridas',
    chat_plan_billing_title: 'Renovación',
    chat_plan_default_billing: 'Mensual',
    chat_plan_label: 'Conversaciones',
    chat_plan_title: 'Tu plan de conversaciones',
    chat_plan_type_label: 'Tipo de Plan',
    discount_half_yearly: '6 meses',
    discount_monthly: '1 mes',
    discount_quarterly: '3 meses',
    discount_yearly: '12 meses',
    enjoy_doppler_title: 'Disfruta Doppler',
    enjoying_benefits_doppler_title: 'Ya puedes empezar a disfrutar de los beneficios de tus planes de Doppler.',
    go_to_home_link: 'IR AL INICIO',
    landing_billing_title: 'Facturación',
    landing_packages_title: 'Paquetes',
    landing_your_landings_pages_plan_title: 'Tu plan de Landings Pages',
    mercado_pago_check_email_with_invoice_message: 'Dentro de las próximas 48 horas hábiles recibirás un mail con la confirmación de pago y podrás empezar a disfrutar de tu nuevo plan.',
    mercado_pago_contact_support: 'Si tienes dudas respecto al estado del pago puedes escribirnos a <Bold>billing@fromdoppler.com</Bold>',
    mercado_pago_error_in_pay: 'En caso de que el cobro sea rechazado te contactaremos para ayudarte a elegir otro medio de pago.',
    mercado_pago_pay_the_invoice_message: 'La factura con el detalle de pago será enviada al destinatario que hayas indicado.',
    mercado_pago_purchase_finished_title: 'Tu pago está siendo procesado',
    mercado_pago_steps_title: 'No dejes de leer la siguiente información:',
    mercado_pago_warning_message: 'Estamos procesando tu pago a través de Mercado Pago, esto puede demorar hasta 48 horas hábiles.',
    onsite_plan_acquired_prints: 'Impresiones adquiridas',
    onsite_plan_billing_title: 'Renovación',
    onsite_plan_default_billing: 'Mensual',
    onsite_plan_label: 'OnSite',
    onsite_plan_title: 'Tu plan de on-site',
    onsite_plan_type_label: 'Tipo de Plan',
    plan_type: 'Tipo de Plan',
    plan_type_monthly_deliveries: 'Envíos por Plan',
    plan_type_monthly_deliveries_availables: 'Envíos Disponibles',
    plan_type_monthly_deliveries_label: 'Por Envíos',
    plan_type_monthly_deliveries_monthly_renovation: 'Mensual',
    plan_type_prepaid: 'Créditos por Plan',
    plan_type_prepaid_availables: 'Créditos Disponibles',
    plan_type_prepaid_label: 'Por Créditos',
    plan_type_prepaid_no_expiration: 'Sin vencimiento',
    plan_type_prepaid_promocode: 'Créditos de regalo',
    plan_type_subscribers: 'Contactos por Plan',
    plan_type_subscribers_availables: 'Contactos disponibles',
    plan_type_subscribers_label: 'Por Contactos',
    purchase_finished_title: 'Compra Finalizada',
    renewal_type_title: 'Renovación',
    start_using_new_plan_button: 'Comienza a usar tu nuevo Plan',
    title: `¡Excelente! Has actualizado tu Plan`,
    transfer_check_email_with_invoice_message: 'Revisa tu correo, y dentro de las proximas 24 horas hábiles recibirás la factura con todos los datos para realizar el pago.',
    transfer_confirmation_message: 'Una vez que confirmemos la recepción del pago te avisaremos por mail. ¡Ya podrás disfrutar de tu nuevo Plan!',
    transfer_explore_button: 'Explorar',
    transfer_explore_message: 'Mientras tanto, te invitamos a explorar tu cuenta :)',
    transfer_pay_the_invoice_message: 'Realiza el pago.',
    transfer_purchase_finished_title: 'Tu factura está en camino',
    transfer_send_the_receipt_message: 'Envía el comprobante de pago a <Bold>billing@fromdoppler.com</Bold> para que podamos activar tu nuevo Plan.',
    transfer_send_the_receipt_with_not_upgrade_pending_message: 'Envía el comprobante de pago a <Bold>billing@fromdoppler.com</Bold> para que podamos contabilizarlo.',
    transfer_steps_title: 'Sigue ESTOS pasos para completar el proceso de pago:',
    transfer_title: '¡Excelente! Has solicitado la actualización de tu Plan',
    transfer_warning_message: 'Necesitas completar el proceso de pago para disponer de tu nuevo Plan.',
    unlimited_title: 'Ilimitados',
  },
  cloud_tags: {
    tag_already_exist: `El tag {tagName} ya existe.`,
    tag_limit_exceeded: `Solo puede agregar {max} tags.`,
  },
  collaborator_edition: {
    confirm_password: 'Confirmar contraseña',
    current_password: 'Contraseña actual',
    new_password: 'Nueva contraseña',
  },
  collaborators: {
    add_collaborator: 'Nuevo Colaborador',
    edition_subtitle: `Edita los datos que necesitas para poder continuar utilizando tu cuenta.
    ¡No olvides guardar tu información!`,
    form_modal: {
      description: `El colaborador tendrá acceso a gestionar tu cuenta de Doppler. Podrá realizar envio de campañas,
      gestión de listas, edición de automations y demás funcionalidades`,
      email: 'Email',
      email_placeholder: 'usuario_owner@ejemplo.com',
      success_subtitle: `Tu Colaborador recibirá la invitación en su casilla de correo electrónico.
    Una vez aceptada la invitación, podrá comenzar a gestionar tu cuenta.`,
      success_title: 'Has añadido a tu Colaborador con éxito',
    },
    menu: {
      disable: 'Inactivar Colaborador',
      edit: 'Editar permisos',
      invite: 'Reenviar Invitación',
    },
    meta_title: 'Doppler | Configuración de perfiles',
    subtitle: 'Desde esta sección podrás asignar colaboradores que te ayuden a gestionar tu cuenta.',
    table: {
      email: 'Email',
      firstname: 'Nombre',
      invitation_date: 'Fecha de invitación',
      lastname: 'Apellido',
      status: 'Estado',
      statusType: {
        APPROVED: 'Aprobada',
        CANCELED: 'Cancelada',
        EXPIRED: 'Expirada',
        PENDING: 'Pendiente',
        REJECTED: 'Rechazada',
      },
    },
    title: 'Configuración de perfiles',
    title_second: 'Colaboradores de tu cuenta',
  },
  common: {
    accept: `Aceptar`,
    advanced_preferences: `Integraciones y Preferencias Avanzadas`,
    back: `Atrás`,
    breadcrumb_plans: 'Planes',
    breadcrumb_plans_url: `${urlPlanSelection}`,
    cancel: `Cancelar`,
    connect: `Conectar`,
    control_panel: `Panel de Control`,
    control_panel_section_url: `${urlControlPanel}`,
    control_panel_url: `${urlControlPanelMain}`,
    conversations_index_url: `${urlConversations}/external-login`,
    copyright_MD: `© ${year} Doppler LLC. Todos los derechos reservados. [Política de Privacidad y Legales](${urlPrivacy}).`,
    emails: 'Emails',
    empty_data: `Tu dominio no tiene datos para mostrar en esta sección aún.`,
    feature_no_available: `La funcionalidad no está disponible aún`,
    finish: 'Finalizar',
    hide: `Ocultar`,
    homeUrl: `${urlDraft}`,
    hours_abbreviation: 'hrs',
    integrations: `Integraciones`,
    message: `Mensaje`,
    message_last_plan: `Cuéntanos tus necesidades y diseñaremos el Plan perfecto para ti`,
    message_success: `¡Hecho! Tu solicitud ha sido enviada`,
    new: `Nueva`,
    next: `Siguiente`,
    optional_message: `Mensaje (Opcional)`,
    recaptcha_legal_MD: `Sitio protegido por reCAPTCHA. [Política de Privacidad](https://policies.google.com/privacy?hl=es) y [Condiciones del Servicio](https://policies.google.com/terms?hl=es) de Google.`,
    save: `Guardar`,
    send: `Enviar`,
    show: `Mostrar`,
    something_wrong: `¡Ouch! Ocurrio un error inesperado`,
    synchronizing: `Syncronizando`,
    ui_library_image: `${process.env.PUBLIC_URL}/images/{imageUrl}`,
    unexpected_error: `¡Ouch! Ocurrio un error inesperado, intenta nuevamente`,
    unsaved_changes_message: `Es posible que no se guarden los cambios que realizó.`,
  },
  contact_policy: {
    amount_description: 'Enviar hasta',
    error_invalid_range_msg_MD: 'Puedes configurar hasta **999 Emails y 30 días** como máximo.',
    exclude_list_description_MD: `
Puedes seleccionar **hasta 10 Listas** para que sean excluídas del límite de envíos. Los Contactos de las Listas excluídas **recibirán todas tus Campañas.**`,
    exclude_list_selector: {
      description_MD: `Los Contactos de las Listas que excluyas recibirán todas tus Campañas. **Recuerda que puedes seleccionar hasta {maxToSelect} Listas.**`,
      max_limit_exceeded: 'Has seleccionado el máximo de Listas que puedes excluir de la Política de Contacto.',
      title: 'Excluir Listas de la Política de Contacto',
    },
    exclude_list_title: `Excluir Listas`,
    interval_description: 'en un plazo de',
    interval_unit: 'días',
    meta_title: 'Doppler | Política de Contacto',
    promotional: {
      action_text: 'Ver Planes PLUS',
      description_MD: 'Contrata un Plan Plus y accede a esta funcionalidad para definir la **cantidad máxima de Emails** que tus Contactos recibirán en un período determinado. También podrás:',
      features: {
        exclude_campaigns_MD: '**Excluir Campañas** de la Política de Contacto.',
        exclude_lists_MD: '**Excluir Listas** de la Política de Contacto.',
      },
      paragraph: 'Optimiza los resultados de tus Campañas definiendo una Política de Contacto acorde a tu estrategia.',
      upgrade_plan_url: `${urlUpgradePlan}`,
    },
    select_lists: 'Seleccionar Listas',
    shipments_quantity: {
      subtitle_MD: `
Define la **cantidad máxima de Emails** que tus Contactos podrán recibir en un período de tiempo determinado.

¿Quieres saber más? Presiona [HELP](${urlHelp}/politica-de-contacto).`,
      title: 'Cantidad de envíos',
    },
    success_msg: 'Los cambios han sido guardados.',
    time_restriction: {
      error_invalid_range_of_hours_msg: 'Puedes configurar la hora en el rango **de 0 a 23**.',
      hour_from_aria_label: 'Hora Desde',
      hour_to_aria_label: 'Hora Hasta',
      legend: 'Configura y establece un rango de envíos para tus Campañas. Selecciona cuándo deseas que se ejecute el envío: ',
      time_slot_hour_from_label: 'Desde',
      time_slot_hour_to_label: 'Hasta',
      time_slot_toggle_text: 'Sólo en determinada franja horaria teniendo en cuenta el huso horario de tu cuenta.',
      title: 'Restricción horaria',
      weekdays_toggle_text: 'Sólo de lunes a viernes',
    },
    title: 'Política de Contacto',
    toggle_text: 'Definir número máximo de envíos',
    tooltip_max_limit_exceeded: `Has seleccionado el máximo de Listas que puedes excluir de la Política de Contacto.`,
  },
  control_panel: {
    account_preferences: {
      account_information_title: 'Información de la cuenta',
      account_movements_title: 'Movimientos de la Cuenta',
      billing_information_title: 'Información de Facturación',
      collaborator_edition_title: 'Edición de datos Colaborador',
      collaborators_title: 'Configuración de Perfiles',
      contact_information_title: 'Información de Contacto',
      plans_and_specs_title: 'Planes y Características',
      sms_settings_title: 'Configuración de SMS',
      title: 'Preferencias de Cuenta',
    },
    advanced_preferences: {
      api_key: 'Doppler API',
      custom_domain: 'Dominios de Formularios',
      domain_key: 'DKIM, SPF y DMARC',
      title: 'Preferencias Avanzadas',
    },
    campaign_preferences: {
      bounce_handling_title: 'Administrador de Rebotes',
      contact_policy_title: 'Política de Contacto',
      email_parameter_title: 'Parámetros en Links',
      footer_and_header_preferences_title: 'Encabezado y Pie de Email',
      site_tracking_title: 'Seguimiento en Sitio y Notificaciones Push',
      subscribers_scoring_title: 'Calificación de Suscriptores',
      title: 'Preferencias de Campañas',
      unopened_mails_config_title: 'Administración de Emails No Abiertos',
      unsubscription_link_title: 'Link de Remoción',
    },
    social_preferences: {
      auto_publish_title: 'Auto Publicar',
      rss_preferences_title: 'Canal RSS',
      social_network_title: 'Compartir en Redes Sociales',
      title: 'Preferencias de Redes Sociales',
      viralization_title: 'Opciones de Viralización',
    },
    subtitle_MD: 'Aquí encontrarás toda la **información relativa a tu cuenta** y podrás configurar tus **Preferencias de Campañas y dominios**.',
    title: 'Panel de Control',
  },
  conversations: {
    actionText: 'Pruébalo Gratis',
    actionText_expired: 'Elige tu plan',
    actionUrl: `${urlPreUpgradePlan}?origin=conversations_promo_page`,
    description: 'Aumenta tus ventas un 40% con una solución, centralizando todos tus canales en una sola plataforma. ¡Ahorra tiempo y asegura cada venta!',
    features: {
      decision_tree_MD: '**Árbol de Decisiones:** Diseña el recorrido conversacional que tendrán los usuarios cada vez que se comuniquen con tu marca.',
      social_media_chatbot_MD: '**Social Media Chatbot:** Administra en un solo tablero las consultas que llegan de Facebook e Instagram, brindando atención personalizada.',
      web_chatbot_MD: '**Web Chatbot:** Gestiona la atención de tus usuarios en tiempo real y convierte a tus visitas en clientes.',
      whatsApp_chatbot_MD: '**WhatsApp Chatbot:** Gestiona la atención de tus usuarios en tiempo real y convierte a tus visitas en potenciales clientes.',
      whatsApp_marketing_MD: '**WhatsApp Marketing:** Automatiza y personaliza mensajes instantáneos y capta mayor audiencia.',
    },
    paragraph_free_MD: '**¡Implementa Conversaciones en tu Estrategia de Marketing!** Puedes probarlo durante tu período gratuito o hasta que utilices 200 Conversaciones.',
    paragraph_free_expired_MD: '**¡Implementa Conversaciones en tu Estrategia de Marketing!**  Adquiere un plan según tus necesidades.',
    paragraph_not_free_MD: '**¡Implementa Conversaciones en tu Estrategia de Marketing!** Puedes probarlo gratis durante 90 días o hasta que utilices 200 Conversaciones.',
    title: 'Doppler Conversaciones',
  },
  dashboard: {
    campaigns: {
      link_title: 'VER TODAS',
      link_title_url: `${urlDopplerLegacy}/Campaigns/Sent/`,
      overlayMessage: `Aquí encontrarás información sobre el rendimiento de tus últimas campañas.`,
      overlayMessageButton: '<Link>Crear una campaña</Link>',
      section_name: 'Mis Campañas',
      totalCampaigns: 'Envíos totales',
      totalCtr: 'Tasa de Clics',
      totalOpen: 'Tasa de Apertura',
    },
    contacts: {
      link_title: 'VER TODOS',
      link_title_url: `${urlMasterSubscriber}`,
      overlayMessage: `Aquí encontrarás los datos más relevantes sobre tus listas de contactos.`,
      overlayMessageButton: '<Link>Agregar contactos</Link>',
      section_name: 'Mis Contactos',
      totalContacts: 'Contactos',
      totalNewContacts: 'Contactos nuevos',
      totalRemovedContacts: 'Contactos removidos',
    },
    first_steps: {
      completed_message: `
<Paragraph>¡Excelente! Cumpliste con éxito los primeros pasos en Doppler. Ahora te serán útiles unos atajos a las funciones más usadas.</Paragraph>
      `,
      enable_quick_actions: `Activar las acciones rápidas`,
      has_campaings_created_description_MD: `
<Paragraph>Elige el tipo de campaña que se adapta mejor a ti y <Bold><Link>haz tu primer envío</Link></Bold>.</Paragraph>
      `,
      has_campaings_created_title: `Crea tu primera campaña`,
      has_campaings_created_url: `${urlDopplerLegacy}/Campaigns/BasicInfo`,
      has_campaings_sent_description_MD: `
<Paragraph><Bold><Link>Realiza el seguimiento de tus métricas</Link></Bold> en el reporte de cada campaña que envíes.</Paragraph>
      `,
      has_campaings_sent_title: `Revisa tus resultados`,
      has_campaings_sent_url: `${urlReports}/SentCampaigns.aspx`,
      has_domains_ready_description_MD: `
<Paragraph><Bold><Link>Configura DKIM, SPF y DMARC</Link></Bold> para que tus contactos reciban tus envíos exitosamente.</Paragraph>
      `,
      has_domains_ready_title: `Asegúrate de no terminar en spam`,
      has_domains_ready_url: `${urlDkim}`,
      has_list_created_description_MD: `<Paragraph><Bold><Link>Crea una lista</Link></Bold> agregando tus contactos, o haz un formulario y consigue nuevos.</Paragraph>`,
      has_list_created_title: `Crea tu primera lista`,
      has_list_created_url: `${urlCreateSubscriberList}`,
      section_name: 'Primeros pasos en Doppler',
      welcome_description_MD: `¿Quieres hacer del Email Marketing tu mejor aliado? Pon en marcha tu estrategia con estas **4 acciones** 👇`,
      welcome_title: 'Has creado una cuenta. ¡Bien hecho!',
    },
    lastMonth: 'ÚLTIMOS 30 DÍAS',
    learn_with_doppler: 'Aprende con Doppler',
    meta_title: 'Doppler | Tablero de Inicio',

    postListBlog_1_description:
      'Del 26 al 28 de Noviembre. Conferencias, Workshops, Networking, Sorteos ¡y mucho más! Escucha a Google, Meta, Canva, Arcos Dorados y más marcas disertar sobre tendencias y estrategias en Marketing Digital.',
    postListBlog_1_link: 'https://goemms.com/digital-trends?utm_source=fromdoppler&utm_medium=destacadotablero&utm_campaign=cw-captacionleadsEMMSDT24-invitacion-nov24',
    postListBlog_1_link_description: 'Únete gratis',
    postListBlog_1_title: 'EMMS Digital Trends 2024 🚀 Evento online y gratuito',

    postListBlog_2_description: 'Convierte visitantes en prospectos con los Formularios Pop Up.\n¡Están 100% bonificados en tu plan!',
    postListBlog_2_link: 'https://help.fromdoppler.com/es/formulario-de-suscripcion/?origin=destacadotablero',
    postListBlog_2_link_description: 'Mira cómo implementarlos',
    postListBlog_2_title: '#FuncionalidadDestacada',

    postListHelp_1_description: 'Gestiona Chatbots en WhatsApp, Instagram, Facebook y tu Sitio Web, ¡todo desde una sola plataforma!',
    postListHelp_1_link: 'https://app.fromdoppler.com/conversations/?origin=destacadotablero',
    postListHelp_1_link_description: 'Descubre más',
    postListHelp_1_title: 'Nueva Solución | Doppler Conversaciones 💣',

    postListHelp_2_description: '¿Necesitas asesoría y acompañamiento exclusivo, IPs dedicadas o funcionalidades extras?',
    postListHelp_2_link: 'https://app.fromdoppler.com/email-marketing-exclusive',
    postListHelp_2_link_description: 'Escríbenos',
    postListHelp_2_title: 'Servicios Adicionales',

    quick_actions: {
      create_form: `Crear formulario`,
      create_form_url: `${urlDopplerLegacy}/Lists/Form/`,
      launch_automation: 'Crear automation',
      launch_automation_url: `${urlDopplerLegacy}/Automation/Automation/AutomationApp/`,
      make_campaign: 'Crear campaña',
      make_campaign_url: `${urlDopplerLegacy}/Campaigns/BasicInfo`,
      make_contact_list: 'Armar lista de contactos',
      make_contact_list_url: urlCreateSubscriberList,
      section_name: 'Acciones rápidas',
      send_push_notification: `Enviar notificación push`,
      send_push_notification_url: `${urlDopplerLegacy}/ControlPanel/CampaignsPreferences/SiteTrackingSettings`,
      send_sms: 'Enviar SMS masivos',
      send_sms_url: `${urlDopplerLegacy}/Automation/EditorConfig?idTaskType=12`,
    },

    total: 'TOTAL',
    welcome_message: 'Hola ',
    welcome_message_header: `Este es tu **Tablero de Inicio**. Aquí encontrarás métricas relevantes de tu cuenta y recursos complementarios para tu estrategia.`,
  },
  default_banner_data: {
    background_url: `${process.env.PUBLIC_URL}/images/violet-yellow.png`,
    description: 'Clásicos y pop-ups con suscripción Simple o Doble Opt-In. ¡Tú eliges cómo quieres que luzcan, qué datos solicitar y dónde ubicarlos!',
    functionality: 'Formularios de suscripción',
    image_url: `${process.env.PUBLIC_URL}/images/login-es.png`,
    title: 'Suma contactos a tus Listas con Formularios personalizados',
  },
  empty_notification_text: `No tienes notificaciones pendientes.`,
  exclusive_form: {
    advisory: 'Asesoría y Consultoría.',
    custom_onboarding: 'Onboarding personalizado',
    custom_reports: 'Reportes a medida',
    dedicated_ip: 'IPs Dedicadas',
    demo: 'ENVIAR',
    description: '¿Necesitas funcionalidades especiales para tu negocio? Cuéntanos cuáles son y podremos ayudarte con un Plan pensado para ti.',
    design_layout_emails: 'Diseño, maquetacion y envio de Emails',
    development_custom_features: 'Desarrollo de Funcionalidades personalizadas',
    features_for_you: 'Funcionalidades a tu medida',
    form_features: 'Selecciona las características que te interesan:',
    form_hour_contact: '¿En qué horario podemos llamarte?',
    form_legend: 'Completa el siguiente formulario de planes',
    form_title: 'Completa este Formulario y te contactaremos en breve.',
    message: 'Cuéntanos que necesitas',
    meta_description: 'Características especiales pensados para los usuarios más exigentes.',
    new: 'Nueva',
    others: 'Otras',
    strategic_accompaniment: 'Acompañamiento Estratégico.',
    success: '¡Excelente! Nos pondremos en contacto a la brevedad',
    testAb: 'Campañas Test A/B',
    title: 'Servicios adicionales',
  },
  faq: {
    answer_1: `**Tarjeta de crédito internacional** Visa, Mastercard o American Express; **transferencia bancaria** a partir de un monto mínimo (para clientes de Argentina, México y Colombia);
    **tarjeta de crédito a través de Mercado Pago** (para Argentina).`,
    answer_2: `**No, puedes darte de baja cuando quieras** y solo se cobrará el mes en curso. Si necesitas un contrato por la prestación del servicio, podemos hacerlo.`,
    answer_3: `**Sí, todos los Planes se renuevan automáticamente** tanto si pagas todos los meses o por adelantado. La renovación se realiza el **primer día del mes**.`,
    answer_4: `Podrías adquirir un **Plan por Envíos**, que ofrece un **costo unitario por Email más bajo** a medida que aumenta el tamaño del Plan.
    Y si tienes alguna otra necesidad, podemos cotizar un **Plan con Servicios adicionales**.`,
    answer_5: `No. **Los Créditos son acumulables y no se vencen**. Puedes utilizarlos para enviar Campañas y hacer recompras cuando quieras.`,
    answer_6: `Una vez que **elijas el Plan**, al seleccionar el **Método de Pago** verás tu **resumen de compra** a la derecha de la pantalla.
    Allí podrás ingresar tu Código de Promoción, y si este es válido verás el descuento que se aplicará a la compra del Plan. _**Los Códigos de Promoción no son acumulables con descuentos por pago adelantado**_.`,
    answer_7: `**Sí. Cualquier Plan por Envíos, Contactos o Créditos** permite enviar Campañas de SMS y obtener Reportes sobre ellos. Ten en cuenta que **los SMS no vienen incluídos dentro del precio del Plan**.`,
    answer_8: `En los Planes por Envíos, el total que hayas contratado se renovará el primer día del mes y **no se acumulan**.`,
    answer_9: `No. Los Contactos que hayas importado en un primer momento en una cuenta gratuita se contabilizarán dentro del total que hayas contratado.`,
    question_1: `¿Cuáles son los métodos de pago disponibles?`,
    question_2: `¿Hay un tiempo mínimo de permanencia para solicitar la baja del servicio?`,
    question_3: `¿Los Planes se renuevan automáticamente?`,
    question_4: `Tengo más de 100.000 Contactos. ¿Qué Plan me conviene contratar?`,
    question_5: `En los Planes por Créditos, ¿hay una fecha límite para usarlos?`,
    question_6: `¿Cómo aplico un Código de Promoción para contratar un Plan?`,
    question_7: `¿Es necesario un Plan Pago para hacer SMS Marketing?`,
    question_8: `¿Qué pasa si no uso todos los Envíos que contraté por mes?`,
    question_9: `Al comprar un Plan, ¿puedo reemplazar los Contactos que subí en la versión gratis?`,
    title: `Preguntas frecuentes sobre Planes`,
  },
  feature_panel: {
    email_automation: `Email Automation`,
    email_automation_description: `Llega con el mensaje adecuado en el momento justo`,
    email_automation_remarks: `Envía Emails 100% personalizados de acuerdo al comportamiento y los intereses de tus Suscriptores. ¡Gana tiempo y ahorra dinero!`,
    forms: `Formularios de suscripción`,
    forms_description: `Suma contactos a tus Listas con Formularios personalizados`,
    forms_remarks: `Clásicos y pop-ups con suscripción Simple o Doble Opt-In. ¡Tú eliges cómo quieres que luzcan, qué datos solicitar y dónde ubicarlos!`,
  },
  footer: {
    iso: `Certificación de Calidad ISO 9001:2008`,
  },
  forgot_password: {
    back_login: `¿Recordaste tu Contraseña? ¡Haz clic aquí y vuelve atrás!`,
    back_login_after_forgot: `Volver al Log in`,
    blocked_account_MD: `Desactivamos tu cuenta. Contáctanos para ayudarte a recuperarla.`,
    blocked_account_MD_link: `Contactar a soporte`,
    button_request: `Solicitar`,
    confirmation_message_MD: `
¡Revisa tu casilla!

Encontrarás un Email con los pasos a seguir.`,
    copyright_MD: `© ${year} Doppler LLC. Todos los derechos reservados. [Política de Privacidad y Legales](${urlPrivacyFromForgot}).`,
    description: `¡No te preocupes! Nos sucede a todos. Ingresa tu Email y te ayudaremos a recuperarla.`,
    expired_data: `Tus datos expiraron. Por favor regresa al Email que te enviamos para restablecer tu contraseña.`,
    expired_link: `Este link ya no funciona.`,
    image_path: `${loginBannerImagePath}`,
    max_attempts_sec_question: `Ingresaste una respuesta incorrecta varias veces.`,
    max_attempts_sec_question_link: `Restablece tu contraseña`,
    max_attempts_sec_question_start_new_process: `para comenzar de nuevo el proceso.`,
    pass_reset_ok: `Actualizaste tu contraseña.`,
    placeholder_email: `¡Psst! Es el Email con el que creaste tu cuenta`,
    recover_password_link: `Recuperar contraseña.`,
    url_site: `${urlSiteFromForgot}`,
  },
  forms: {
    label_contact_schedule: `¿En qué horario podemos llamarte?`,
    label_email: `Email: `,
    label_firstname: `Nombre: `,
    label_lastname: `Apellido: `,
    label_phone: `Teléfono: `,
    placeholder_phone: `9 11 2345-6789`,
  },
  header: {
    availables: 'disponibles',
    enabled: 'HABILITADO',
    help_url: `${urlHelp}`,
    plan_emails: 'Emails',
    plan_prepaid: 'Plan Premium por Créditos',
    plan_suscribers: 'Suscriptores',
    profile: 'Perfil',
    send_mails: 'Envío de Emails',
    send_request: 'SOLICITUD ENVIADA',
    tooltip_last_plan: 'Estamos diseñando un Plan a la medida de tus necesidades. ¡Te contactaremos pronto!',
  },
  integrations: {
    external_integrations: {
      dynamics_link_url: `${urlHelp}/como-integrar-dynamics-con-doppler`,
      dynamics_title: 'Microsoft Dynamics 365',
      empretienda_link_url: `${urlHelp}/funcionalidades-doppler-para-empretienda`,
      empretienda_title: 'Empretienda',
      facebook_title: 'Facebook',
      infor_title: 'Infor',
      leanrpress_title: 'LearnPress',
      payU_title: 'PayU',
      sumaprop_title: 'Sumaprop',
      tiendup_title: 'TiendUp',
      title: 'Integraciones Externas',
      viewed_title: 'Viewed',
      wix_title: 'Wix',
      wizell_title: 'Wizell',
      wordpress_link_url: `${urlHelp}/como-integrar-formularios-de-wordpress-con-doppler`,
      wordpress_title: 'Wordpress',
      xintel_title: 'Xintel',
      zeus_title: 'Zeus',
    },
    native_integrations: {
      big_query_title: 'BigQuery',
      bmw_rsp_crm_title: 'RSP CRM',
      easycommerce_title: 'Easycommerce',
      empretienda_title: 'Empretienda',
      google_Analityc_title: 'Google Analytics',
      jumpseller_title: 'Jumpseller',
      magento_title: 'Adobe Commerce',
      mercadolibre_title: 'MercadoLibre',
      mercadoshops_title: 'Mercado Shops',
      mitienda_title: 'MiTienda',
      prestashop_title: 'PrestaShop',
      shopify_title: 'Shopify',
      tiendanube_title: 'Tiendanube',
      title: 'Integraciones Nativas',
      tokko_title: 'Tokko Broker',
      typeform_title: 'Typeform',
      unbounce_title: 'Unbounce',
      vtex_title: 'VTEX',
      woocommerce_title: 'WooCommerce',
      zoho_title: 'Zoho CRM',
    },
    status_alert: 'Problemas de conexión',
    status_connected: 'Conectado',
    status_not_connected: 'No conectado',
    title: 'Integraciones',
    zapier_integrations: {
      eventbrite: 'Eventbrite',
      go_to_webinar: 'GoToWebinar',
      hubspot: 'HubSpot',
      lead_ads: 'Facebook Lead Ads',
      optin_monster: 'OptinMonster',
      salesforce: 'Salesforce',
      title: 'Integraciones via Zapier',
      zapier: 'Zapier',
    },
  },
  invoices_list: {
    amount_column: `Importe`,
    balance_column: `A Saldar`,
    control_panel_account_preferences_url: `${urlControlPanelMain}?section=AccountPreferences`,
    control_panel_billing_information_section: `Información de Facturación`,
    control_panel_billing_information_url: `${urlControlPanel}/AccountPreferences/BillingInformationSettings`,
    control_panel_section: `Panel de Control`,
    creation_date_column: `Fecha Creación`,
    currency_column: `Moneda`,
    date_column: `Fecha`,
    document_FC: `Factura`,
    document_NC: `Nota de Crédito`,
    document_number_column: `Número Comprobante`,
    document_type_column: `Tipo Comprobante`,
    download_msg: `Descargar`,
    downloads_column: `Descargas`,
    due_date_column: `Fecha Vencimiento`,
    error_msg: `¡Ouch! Ocurrió un error al intentar cargar tus facturas.`,
    no_data_msg: `Aún no hay facturas emitidas para mostrar.`,
    no_download_msg: `Aún no hay factura para descargar`,
    paid_to_date_column: `Pagado`,
    sub_title: `Aquí está detalle de las facturas que hemos emitido. Recuerda que puedes descargar cada una.`,
    title: `Facturas Emitidas`,
  },
  landing_selection: {
    choose_landings: `Elige cuántas Landing Pages necesitas`,
    description: `Expande tu presencia online y fomenta la interacción con tu marca, creando páginas de destino específicas para tus acciones de Marketing.`,
    description1: `Crea Páginas de Destino responsivas y asegura más conversiones en todos los dispositivos. <strong>¡Multiplica tus Leads en pocos pasos!</strong>`,
    description2: `Ahorra dinero y recursos con nuestras plantillas de <strong>Landing Pages optimizadas para generar conversiones efectivas.</strong>`,
    modal: {
      buy_now_button: 'Comprar ahora',
      close_button: `NO QUIERO ESTE BENEFICIO.`,
      description: `Capta más Leads y potencia tus ventas con Páginas de Destino optimizadas.`,
      link_to_buy: `Empieza ahora`,
      more_information_label: 'Más información',
      title: `Crea Landing Pages`,
    },
    pack_addons_title: `Descubre nuestros add-ons`,
    pack_from: `Packs desde US$ {price}/mes`,
    pack_of_landing_pages_with_plural: `{packs, plural, one {Pack de # Landing Page}other {Pack de # Landings Pages} }`,
    pack_price: `US$ {price}/mes`,
    pack_unit_price: `Precio: US$ {unitPrice} c/u`,
    remove_landings_label: `Quitar landing pages`,
    shopping_cart: {
      pack_of_landing_pages: `{packagesQty} packs de {landingsQty} Landings c/u`,
      title: `Packs de Landing Pages`,
    },
    subtitle1: `Aumenta tus Leads`,
    subtitle2: `Maximiza tu rentabilidad`,
    title: `Potencia tus ventas con Landing Pages`,
    user_messages: {
      legend_after_remove: `La cancelación de este Add-on se aplicará de inmediato y el cargo por Landing Pages ya no se incluirá en tu próxima factura.`,
      warning_description: `Si quieres reducir tu pack, primero debes archivar tus Landing Pages creadas. `,
      warning_link_title: `VER MIS LANDINGS`,
    },
  },
  loading: `Cargando...`,
  login: {
    button_login: `Ingresar`,
    collaborator_login_description: 'Su email ya se encuentra registrado en Doppler como colaborador. Al crear una cuenta propia podrá conservar los mismos accesos que posee con ese rol.',
    collaborator_login_title: 'Confirmación de Accesos',
    copyright_MD: `© ${year} Doppler LLC. Todos los derechos reservados. [Política de Privacidad y Legales](${urlPrivacyFromLogin}).`,
    enter_doppler: `Ingresa a tu cuenta`,
    enter_doppler_sub: `¡Hoy es un buen día para potenciar tu negocio con el poder del Email, Automation & Data Marketing!`,
    forgot_password: `¿No recuerdas tu Contraseña?`,
    head_description: `Atrae, convierte y fideliza clientes con el poder del Email Marketing Automation. ¡Ingresa a Doppler!`,
    head_title: `Email Marketing Automation gratis y con envíos ilimitados | Doppler`,
    image_path: `${loginBannerImagePath}`,
    label_user: `Nombre de Usuario: `,
    placeholder_email: `¡Psst! Es tu Email`,
    placeholder_password: 'Escribe tu contraseña',
    signup: `Regístrate gratis`,
    url_site: `${urlSiteFromLogin}`,
    you_want_create_account: `¿Aún no tienes una cuenta?`,
  },
  master_subscriber: {
    header_title: `Reporte de Actividad General del Suscriptor`,
    page_description: `Mediante este reporte usted podrá conocer la actividad general de un suscriptor determinado`,
    page_title: `Doppler | Historial por Suscriptor`,
    search_form: {
      aria_label: `Formulario de filtros para buscar Historial de Suscriptores`,
      aria_search_field: `Ingrese un Email, Nombre o Apellido para buscar Historial de Suscriptor`,
      search_field_placeholder: `Busca un suscriptor por su Email, Nombre o Apellido...`,
      search_form_legend: `Busqueda avanzada de Historial de Suscriptor`,
    },
    table_result: {
      aria_label_email: `Email`,
      aria_label_lastname: `Apellido`,
      aria_label_name: `Nombre`,
      aria_label_score: `Puntuación`,
      aria_label_state: `Estado`,
      aria_label_table: `Resultado de Historial de Suscriptores`,
    },
  },
  master_subscriber_current_search: {
    grid_email: `Email`,
    grid_firstname: `Nombre`,
    grid_lastname: `Apellido`,
    grid_ranking: `Ranking`,
    grid_status: `Estado`,
  },
  master_subscriber_sent_campaigns: {
    grid_campaign: `Campaña`,
    grid_clicks: `Clicks Únicos`,
    grid_delivery: `Comportamiento`,
    grid_subject: `Asunto`,
  },
  onsite_promotional: {
    actionText: 'Pruébalo Gratis',
    actionText_expired: 'Elige tu plan',
    actionUrl: `${urlPreUpgradePlan}?origin=onsite_promo_page`,
    description: 'Agrega Widgets, Pop-ups, Formularios inteligentes y otras herramientas interactivas en tu E-commerce o Sitio Web. ¡Capta más clientes y aumenta tus ventas!',
    features: {
      capture_information: 'Captura información de forma personalizada para nutrir tus Listas.',
      display_products_of_interest: 'Exhibe productos de interés y permite retomar compras.',
      offer_complementary_products: 'Ofrece productos complementarios para promover más ventas.',
      product_history: 'Muestra el Historial de Productos que visitó tu cliente.',
      promote_your_products: 'Promociona tus productos más vendidos y fomenta sus ventas.',
    },
    paragraph_free_MD: '¡Implementa OnSite en tu Estrategia de Marketing! Puedes probarlo durante tu período gratuito o hasta que utilices 2.500 Impresiones.',
    paragraph_free_expired_MD: '¡Implementa OnSite en tu Estrategia de Marketing! Adquiere un plan según tus necesidades.',
    paragraph_not_free_MD: '¡Implementa OnSite en tu Estrategia de Marketing! Puedes probarlo gratis durante 90 días o hasta que utilices 2.500 Impresiones.',
    title: 'Doppler OnSite',
  },
  onsite_selection: {
    banner_for_prints: `¿Necesitas más Impresiones? <Link>Escríbenos</Link>.`,
    card: {
      buy_now_button: 'Comprar ahora',
      description: `Agrega Pop-ups, Formularios, vitrinas de productos y otros Widgets que te permitan interactuar con los visitantes de tu Tienda Online.`,
      more_information_label: 'Más información',
      plan_from: `Impresiones desde US$ {price}/mes`,
      title: `Plan OnSite`,
    },
    expiration_free_plan_message: '*Recuerda que al contratar un plan de OnSite, finalizará  de manera automática la versión de prueba de tres meses.',
    how_many_prints_need_message: '¿Cuántas impresiones necesitas por mes?',
    info_tooltip: 'Las impresiones son las vistas de anuncios en el Sitio Web. A mayor tráfico más impresiones.',
    more_option_tickmark_message: 'Más',
    onsite_plan_info: {
      legend: 'Agrega Pop-ups, Formularios, vitrinas de productos y otros Widgets que te permitan interactuar con los visitantes de tu Tienda Online.',
      section_1: {
        legend: 'Crea herramientas que capten y fortalezcan <bold>la conexión con tu marca.</bold>',
        title: 'Incorpora elementos interactivos',
      },
      section_2: {
        legend: 'Consigue clientes potenciales y mejora la Conversión en tu E-commerce.',
        title: 'Maximiza tu rentabilidad',
      },
    },
    selected_onsite_plan: {
      add_to_cart_button: 'Agregar al carrito',
      additional_print_message: '<bold>US$ {additionalPrint}</bold> por Impresión adicional',
      cost_print_message: '<bold>US$ {costPrint}</bold> por Impresión',
      custom_additional_print_message: 'Desde <bold>US$ {additionalPrint}</bold> por Impresión adicional',
      custom_cost_print_message: 'Desde <bold>US$ {costPrint}</bold> por Impresión',
      custom_includes_until_prints_message: 'Incluye desde {prints} Impresiones',
      from: 'Desde ',
      includes_until_prints_message: 'Incluye hasta {prints} Impresiones',
      month_message: 'mes',
      no_onsite_plan_selected_message: 'No se ha seleccionado un plan de on-site',
      prints_plan_message: 'Plan OnSite*',
      remove_from_cart_button: 'Remover del carrito',
    },
    title: 'Agrega Widgets y Pop-ups en tu Sitio Web',
  },
  pagination: {
    go_back_pages: 'Retroceder 5 páginas',
    go_foward_pages: 'Avanzar 5 páginas',
  },
  plan_calculator: {
    advice_for_subscribers_large_plan: '¿Necesitas más Contactos? <Link>Escríbenos</Link>.',
    banner_for_monthly_deliveries: `¿Necesitas más Envíos? <Link>Escríbenos</Link>.`,
    banner_for_prepaid: `¿Necesitas más Créditos? Te recomendamos los <Link>Planes por Envíos</Link>.`,
    banner_for_subscribers: `¿Necesitas más Contactos? Te recomendamos los <Link>Planes por Envíos</Link>.`,
    banner_for_unknown: ' ',
    button_back: 'Volver',
    button_purchase: 'Continuar',
    button_purchase_tooltip: '¡Este es el Plan que tienes actualmente! Modifícalo para continuar.',
    cost_per_email: 'Costo por email excedente',
    current_subscription: 'Actualmente tienes una **suscripción por {period} meses**',
    discount_clarification: 'La renovación es automática y puedes cancelarla cuando quieras. El precio del Plan no incluye impuestos.',
    discount_clarification_prepaid: 'El precio del Plan no incluye impuestos.',
    discount_half_yearly: '6 meses',
    discount_monthly: '1 mes',
    discount_quarterly: '3 meses',
    discount_subscription_half_yearly: 'Posees una suscripción Semestral',
    discount_subscription_monthly: 'Posees una suscripción Mensual',
    discount_subscription_quarterly: 'Posees una suscripción Trimestral',
    discount_subscription_subtitle: 'SOBRE EL PRECIO DEL PLAN',
    discount_subscription_yearly: 'Posees una suscripción Anual',
    discount_title: 'Suscripción',
    discount_yearly: '12 meses',
    per_month: 'por mes',
    plan_plus_subtitle: 'Elige el tipo de Plan y estima su costo deslizando el botón, de acuerdo al número de Contactos o Envíos que necesites.',
    plan_plus_title: 'Plan PLUS',
    plan_premium_subtitle: 'Elige el tipo de Plan y estima su precio deslizando el botón, de acuerdo al número de Contactos, Envíos o Créditos que necesites.',
    plan_premium_title: 'Plan PREMIUM',
    plan_standard_subtitle: 'Elige el tipo de Plan y estima el precio deslizando el botón, de acuerdo al número de Contactos, Envíos o Créditos que necesites.',
    plan_standard_title: 'Plan AVANZADO',
    plan_type_monthly_deliveries: 'por Envíos',
    plan_type_monthly_deliveries_tooltip: 'Planes ideales para quienes envían gran cantidad de Campañas, ya que tienen menor costo unitario por Email. ',
    plan_type_prepaid: 'por Créditos',
    plan_type_prepaid_tooltip: 'Planes ideales para quienes envían Campañas esporádicamente. ¡Los créditos son acumulables y no tienen vencimiento!',
    plan_type_subscribers: 'por Contactos',
    plan_type_subscribers_tooltip: 'Planes basados en la cantidad de Contactos, sin límite de envíos. ¡Pagando por adelantado ahorras hasta 25%!',
    promocode_error_message: `
No pudimos aplicar el descuento.

El código es inválido.`,
    subscription_discount: 'Ahorras {discountPercentage}% sobre el valor del Plan',
    suggestion_for_monthly_deliveries: '¿Realizas más de 10.000.000 de envíos? <Link>Contáctanos y te asesoraremos.</Link>',
    suggestion_for_prepaid: ' ',
    suggestion_for_subscribers: '¿Tienes más de 100.000 contactos? Te recomendamos nuestros <Bold>PLANES POR ENVÍOS</Bold>, <Link>contáctanos y te asesoraremos.</Link>',
    suggestion_for_unknown: ' ',
    with_half_yearly_discount: 'Pago por 6 meses:',
    with_quarterly_discount: 'Pago por 3 meses:',
    with_yearly_discount: 'Pago por 12 meses:',
  },
  plan_types: {
    actual_plan: 'Plan actual',
    agency_banner: {
      button_text: 'SOLICÍTA UNA DEMO',
      description: 'La solución ideal para quienes gestionan múltiples cuentas. Simplifica tu gestión desde el Client Manager.',
      title: 'Agencias',
    },
    calculate_value_button_label: 'Calcular valor',
    contact_plan: {
      comment_card: 'Es el plan ideal si envías frecuentemente tus Campañas a una misma cantidad.',
      description_card: 'Envía Emails ilimitados en base a tu cantidad de Contactos.',
      name_card: 'Contactos',
      ribbonText: 'MÁS ELEGIDO',
    },
    credit_plan: {
      comment_card: 'Es el plan ideal para enviar Campañas ocasionales.',
      description_card: 'Envía Emails a partir de créditos acumulables y sin vencimiento.',
      name_card: 'Créditos',
    },
    discount_argentina_label: `Exclusivo para Argentina`,
    email_plan: {
      comment_card: 'Es el plan ideal si envías numerosas Campañas.',
      description_card: 'Envía una gran cantidad de Emails a un bajo costo unitario.',
      name_card: 'Envíos',
    },
    features_plans: {
      feature_1: 'Campañas de Email clásicas, sociales y Test A/B.',
      feature_2: 'Formularios de Suscripción Clásicos y Pop-up.',
      feature_3: 'Flujos Automatizados.',
      feature_4: 'Notificaciones Push.',
      feature_5: 'Campañas de SMS.',
    },
    free_plan: {
      comment_card: 'Es el plan ideal si deseas probar y descubrir Doppler.',
      description_card: 'Envía Emails ilimitados a 500 Contactos durante 90 días.',
      name_card: 'Gratis',
    },
    functionalities_label: '¿Qué podrás hacer con este Plan?',
    functionality_header: {
      comment: '¡Conoce todo lo que puedes hacer con Doppler!',
      description: 'Todos nuestros planes incluyen todas nuestras funcionalidades.',
      title: 'Conoce todas las funcionalidades',
    },
    meta_title: 'Tipo de planes',
    min_monthly_plan_price: `<Span>Desde</Span> <SpanStrike>US$ {priceStrike}/mes**</SpanStrike><H3>US$ {price}/mes</H3>`,
    min_single_plan_price: `<Span>Desde</Span> <H3>US$ {price}</H3>`,
    page_comment: 'Todos los planes incluyen todas las funcionalidades.',
    page_description: 'Selecciona el mejor plan que se adapte a tu Estrategia de Email Marketing.',
    page_title: 'Tipos de planes',
    payment_methods_banner: {
      title: 'Métodos de pago',
      transfer_label: 'Transferencia Bancaria',
    },
    see_all_features_label: 'Ver todas las funcionalidades',
    table: {
      automation: {
        description_1: 'Programa envíos automáticos cada vez que un usuario abandona su compra y motívalo a que la finalice.',
        description_2: 'Configura envíos en base a las interacciones que tus Contactos hayan tenido con tus Campañas.',
        description_3: 'Programa envíos con contenidos vinculados a las páginas que visitó tu Contacto.',
        description_4: 'Agrega envíos de mensajes de texto a tus Campañas.',
        description_5: 'Comunica las novedades de tu Blog e información de tu marca con envíos programados a tus Contactos.',
        description_6: 'Activa un envío para que sea recibido en el momento oportuno.',
        description_7: 'Los flujos prediseñados son plantillas con varias acciones ya incorporadas. Ahorra tiempo y adáptalos a tu gusto.',
        description_8: 'Convierte el interés de tu Contacto en una compra, a partir de envíos basados en los productos que ha visto en tu E-commerce.',
        description_9: 'Envía una cálida bienvenida cuando una persona se suscribe a tu Sitio Web.',
        name_1: 'Carrito Abandonado',
        name_2: 'Comportamiento en Campaña',
        name_3: 'Comportamiento en Sitio',
        name_4: 'Envío de SMS',
        name_5: 'Envío de RSS',
        name_6: 'Fechas Programadas',
        name_7: 'Flujos Prediseñados',
        name_8: 'Producto Visitado',
        name_9: 'Suscripción a Listas',
        title: 'Automation',
      },
      campaings: {
        description_1: 'Chequea la efectividad para obtener mejores resultados en tus Campañas.',
        description_10: 'Analiza, corrige y mejora los resultados obtenidos en tus Campañas.',
        description_2: 'Crea Campañas creativas y efectivas para conectar con tus Contactos.',
        description_3: 'Vincula tus Campañas con tus redes sociales y aumenta tu alcance.',
        description_4: 'Personaliza el contenido y asunto para potenciar la efectividad de tus Campañas.',
        description_5: 'Pausa el envío, corrige lo que necesites y vuelve a ejecutarla.',
        description_6: 'Programa tus Campañas en el mejor día y horario para cada Contacto.',
        description_7: 'Permite a tus Contactos removerse de tu cuenta y así dejar de recibir tus Campañas.',
        description_8: 'Define cuántas Campañas puede recibir un Contacto en un período de tiempo.',
        description_9: 'Programa el reenvío de tu Campaña a los Contactos que no la hayan abierto o generado rebotes.',
        name_1: 'Asuntos efectivos',
        name_10: 'Reportes',
        name_2: 'Campaña Clásica',
        name_3: 'Campaña Social',
        name_4: 'Campos personalizados',
        name_5: 'Detener Envío en Progreso',
        name_6: 'Envío Inteligente',
        name_7: 'Link de Remoción',
        name_8: 'Política de Contacto',
        name_9: 'Reenvío Automático',
        title: 'Campañas',
      },
      editor: {
        description_1: 'Logra diseños impecables con solo arrastrar y soltar.',
        description_2: 'Genera tus propias imágenes o crea títulos efectivos para potenciar tu comunicación.',
        description_3: 'Comprueba cómo lucirán tus Emails en distintas pantallas y soportes.',
        description_4: 'Diseña tu contenido a partir de diseños pensados para simplificar tu trabajo.',
        description_5: 'Inserta enlaces en imágenes, texto o vínculo a tus redes sociales.',
        description_6: 'Incluye links de PayU para hacer más simple la compra de productos.',
        name_1: 'Diseño atractivo',
        name_2: 'Inteligencia artificial',
        name_3: 'Previsualización de tus Campañas',
        name_4: 'Plantillas prediseñadas',
        name_5: 'Links',
        name_6: 'Botón de pago',
        title: 'Editor',
      },
      forms: {
        description_1: 'Diseña formularios que pueden visualizarse en diferentes dispositivos y pantallas.',
        description_2: 'Capta la atención de tus visitantes para aumentar tu Base de Datos.',
        description_3: 'Asegura la autenticidad de tus Contactos con un Email de confirmación.',
        description_4: 'Configura un formulario oportuno y efectivo en tu sitio.',
        description_5: 'Ajusta los elementos necesarios para dar la identidad visual de tu marca.',
        description_6: 'Optimiza tu Formulario para que aparezca en los principales motores de búsqueda.',
        description_7: 'Solicita a tus Contactos los datos necesarios para enviar tus Campañas.',
        description_8: 'Suma un botón flotante para captar Contactos y redirigirlos a WhatsApp.',
        name_1: 'Formularios Adaptables',
        name_2: 'Formulario Clásico',
        name_3: 'Formulario Doble Opt-in',
        name_4: 'Formulario Pop-up',
        name_5: 'Formularios Personalizados',
        name_6: 'Formularios SEO',
        name_7: 'Formulario Simple Opt-in',
        name_8: 'WhatsApp Pop-up',
        title: 'Formularios',
      },
      integrations: {
        description_1: 'Conecta tu Tienda Online con Doppler y maximiza tus ventas.',
        description_10: 'Sincroniza los contactos y clientes que tienes en tu CRM con Doppler y optimiza tus esfuerzos de venta.',
        description_11: 'Integra ambas plataformas, sincroniza tus productos y potencia las ventas de tu E-commerce.',
        description_12: 'Potencia tus ventas de manera eficaz integrando tu E-learning con tu cuenta de Doppler.',
        description_13: 'Configura el tablero en simples pasos y profundiza en los resultados de tus Campañas.',
        description_14: 'Conecta tu Tienda Online con Doppler e incrementa tus ventas.',
        description_15: 'Sincroniza los Contactos de tu CRM con Doppler y gestiona tus acciones de Marketing Automation.',
        description_16: 'Importa automáticamente tus productos en tus Emails y envía Campañas promocionales.',
        description_17: 'Promociona tus productos y ofertas importándolos automáticamente en tus Emails.',
        description_18: 'Integra tu cuenta con uno de los plugins más utilizados en sitios de WordPress para captar Leads.',
        description_19: 'Vende los productos de tu E-commerce directamente desde tus Campañas de Email gracias a los botones de pago.',
        description_2: 'Conecta y analiza el rendimiento de tus Campañas con Reportes detallados y personalizables.',
        description_20: 'Conecta con Doppler a través de BigQuery y visualiza Reportes avanzados sobre tus Campañas.',
        description_21: 'Importa automáticamente los contactos de tu Tienda a Doppler y envíales Campañas promocionales de tu negocio.',
        description_22: 'Trae los contactos que tienes en Salesforce a Doppler de forma automática.',
        description_23: 'Importa los contactos de tu Tienda Online en Doppler para generar más y mejores ventas.',
        description_24: 'Conecta con Doppler a través de BigQuery y visualiza tus datos de forma interactiva.',
        description_25: 'Sincroniza tu cuenta de Doppler y tu Tienda Online para enviar Campañas y recuperar Carritos Abandonados de tus visitantes.',
        description_26: 'Gestiona tus Campañas y envía comunicaciones personalizadas a los Contactos de tu E-commerce.',
        description_27: 'Importa tus contactos de Tokko en Doppler y envíales Campañas con propiedades de su interés.',
        description_28: 'Sincroniza las respuestas de tus encuestas y sondeos para hacer Email Marketing personalizado con Doppler.',
        description_29: 'Haz crecer tu Base de Datos y tus conversiones integrando Campañas y Landing Pages.',
        description_3: 'Sincronizar tus cuentas para obtener el máximo de ambas plataformas.',
        description_30: 'Importa los contactos de tu Tienda en Doppler y multiplica tus ventas gracias al Email y Automation Marketing.',
        description_31: 'Suma videos autoreproducibles a tus Campañas de Email para captar la atención de tus Contactos.',
        description_32: 'Recolecta información valiosa de los visitantes de tu Sitio Web con los Formularios de Doppler.',
        description_33: 'Envía tus contactos y clientes desde tu cuenta de Wizell hacia Doppler de manera automática.',
        description_34: 'Crea Formularios de Suscripción para incluir en tu Blog y alimentar tus Listas en Doppler.',
        description_35: 'Potencia tus ventas de manera eficaz integrando tu E-commerce con tu cuenta de Doppler.',
        description_36: 'Sincroniza automáticamente tus Listas con los contactos que posees en tu CRM inmobiliario.',
        description_37: 'Integra Doppler con tus aplicaciones preferidas sin pasar horas programando.',
        description_38: 'Integra todas las web apps de Zoho con Doppler y afianza la comunicación con tus prospectos.',
        description_4: 'Aprovecha las mejores funcionalidades de Doppler para potenciar tu E-commerce.',
        description_5: 'Une el poder de Doppler con esta app para organizar y gestionar eventos.',
        description_6: 'Envía Campañas a tus Contactos generados a través de anuncios en esta red social.',
        description_7: 'Averigua en tiempo real cómo se comportan tus Contactos en tu Web al llegar desde tus Campañas.',
        description_8: 'Envía Campañas a tus Contactos generados a través de esta plataforma de conferencias online.',
        description_9: 'Sincroniza tu cuenta con Doppler y convierte a tus prospectos en clientes por medio de tus Campañas de Email.',
        name_1: 'Adobe Commerce',
        name_10: 'Infor',
        name_11: 'Jumpseller',
        name_12: 'LearnPress',
        name_13: 'Looker Studio',
        name_14: 'MiTienda',
        name_15: 'Microsoft Dynamics 365',
        name_16: 'Mercado Shops',
        name_17: 'Mercado Libre',
        name_18: 'OptinMonster',
        name_19: 'PayU',
        name_2: 'BigQuery',
        name_20: 'Power BI',
        name_21: 'PrestaShop',
        name_22: 'Salesforce',
        name_23: 'Shopify',
        name_24: 'Tableau',
        name_25: 'Tiendanube',
        name_26: 'Tiendup',
        name_27: 'Tokko',
        name_28: 'Typeform',
        name_29: 'Unbounce',
        name_3: 'Easycommerce',
        name_30: 'VTEX',
        name_31: 'Viewed',
        name_32: 'WIX',
        name_33: 'Wizell',
        name_34: 'WordPress',
        name_35: 'WooCommerce',
        name_36: 'Xintel',
        name_37: 'Zapier',
        name_38: 'Zoho',
        name_4: 'Empretienda',
        name_5: 'Eventbrite',
        name_6: 'Facebook Lead Ads',
        name_7: 'Google Analytics',
        name_8: 'GoToWebinar',
        name_9: 'Hubspot',
        title: 'Integraciones',
      },
      omni: {
        description_1: 'Envía facturas, comprobantes, confirmaciones de cuenta y comunicaciones seguras y efectivas a tus clientes. Servicio con costos extra.',
        description_2: 'Envía alertas o información relevante a los visitantes de tu Sitio Web o Tienda Online.',
        description_3: 'Agrega envíos de mensajes de texto a tus Campañas. Servicio con costos extra.',
        name_1: 'Email Transaccional',
        name_2: 'Notificaciones Push',
        name_3: 'SMS',
        title: 'Omnicanalidad',
      },
      report: {
        description_1: 'Conoce los resultados de tus Campañas en tiempo real.',
        description_2: 'Cruza información de distintas fuentes y genera Reportes Avanzados.',
        description_3: 'Sincroniza con esta herramienta y conoce las métricas globales.',
        description_4: 'Descubre qué contenido fue más relevante para los enlaces que incluiste en tu Campaña.',
        description_5: 'Conoce en qué zonas geográficas tienen mayores interacciones tus Campañas.',
        description_6: 'Averigua cuál es el alcance de tus Emails en redes sociales.',
        description_7: 'Conoce la cantidad de desuscripciones y las razones por las que tus Contactos han dejado de seguirte.',
        name_1: 'Reportes avanzados',
        name_2: 'Integración con BigQuery',
        name_3: 'Google Looker Studio',
        name_4: 'Mapa de calor',
        name_5: 'Geolocalización',
        name_6: 'Impacto Social',
        name_7: 'Remociones',
        title: 'Reportes',
      },
      segmentation: {
        description_1: 'Añade tus Contactos manualmente, desde un archivo o a través del Formulario de captación/suscripción en tu sitio.',
        description_2: 'Crea segmentos basados en datos relevantes para tus Campañas.',
        description_3: 'Etiqueta tus enlaces y crea Segmentos en base a los clics que realizan en tus Emails.',
        description_4: 'Identifica a aquellos contactos más interesados en tu negocio y reactiva a los que están perdiendo interés.',
        description_5: 'Identifica a tus clientes a través de su comportamiento de compra y envía comunicaciones personalizadas.',
        name_1: 'Importa Contactos',
        name_2: 'Segmentación Demográfica',
        name_3: 'Segmentación por links',
        name_4: 'Segmentación por actividad',
        name_5: 'Segmentación por comportamiento (RFM)',
        title: 'Segmentación',
      },
    },
  },
  plans: {
    monthly_deliveries_amount_description: 'Emails',
    prepaid_amount_description: 'Créditos',
    subscribers_amount_description: 'Contactos',
    unknown_amount_description: ' ',
  },
  promotional_ribbons: {
    coming_soon: 'Próximamente',
    new: 'Nueva',
  },
  reports: {
    datahub_not_domains_title: `Agrega el dominio de tu Web y analiza el comportamiento de tus usuarios`,
    no_domains_MD: `
Registra el o los dominios sobre los que quieres realizar el seguimiento y accede a Reportes
detallados. Descubre cuáles son las páginas más visitadas de tu Sitio Web o E-commerce, cuántos
visitantes poseen un Email que Doppler ha identificado y cuántos no. ¿Necesitas ayuda? [HELP](${urlHelpAdvancedReports}).`,
    no_domains_button: `Agrega tu dominio`,
    no_domains_button_destination: `${urlSiteTracking}`,
    promotional: {
      description_MD: 'Accede a **Reportes detallados** para entender el comportamiento de los visitantes de tu Sitio Web o E-commerce. Descubre:',
      features: {
        feature_1: 'Cuáles son las páginas más visitadas.',
        feature_2: 'Cuántos visitantes ya están en tus Listas de Contacto.',
        feature_3: 'Cuántos visitantes no aún se han suscripto para recibir tus Campañas.',
      },
      link_text: 'REVISAR LOS PLANES',
      paragraph: `Contrata cualquier Plan Pago y accede a esta funcionalidad.`,
      title: `Analiza el comportamiento de tus visitantes y optimiza tus acciones`,
      upgrade_plan_url: `${urlUpgradePlan}`,
    },
  },
  reports_box: {
    to: `a`,
    visits_description_with_email: `Número total de usuarios que visitaron tu Sitio Web y cuyo Correo Electrónico ha sido identificado por Doppler. Si un usuario ingresó varias veces, solo se contabilizará una.`,
    visits_description_without_emails: `Número total de usuarios que visitaron tu Sitio Web y cuyo Correo Electrónico no ha sido identificado por Doppler. Si un usuario ingresó varias veces, solo se contabilizará una.`,
    visits_with_email: `Usuarios con Email`,
    visits_without_emails: `Usuarios sin Email`,
    without_included: `(sin incluir)`,
  },
  reports_daily_visits: {
    title: `Páginas vistas únicas`,
    tooltip_page_views: `Páginas vistas: `,
    tooltip_with_email: `Usuarios con Email: `,
    tooltip_without_email: `Usuarios sin Email: `,
  },
  reports_filters: {
    all_pages: `Todas las paginas`,
    description_MD: `
Descubre cuáles son las páginas más visitadas de tu Sitio Web o E-commerce, cuántos visitantes poseen un Email que Doppler ha identificado y cuántos no. ¡Sigue el recorrido de
los usuarios, detecta puntos de fuga y oportunidades de mejora! Si necesitas ayuda, presiona [HELP](${urlHelpAdvancedReports}).
      `,
    domain: `Dominio`,
    domain_not_verified_MD: `Tu dominio no está verificado. Es necesario para comenzar a obtener datos sobre tus visitas. [VERIFICAR DOMINIO](${urlSiteTracking}).`,
    no_information: `No hay información disponible`,
    pages: `Página`,
    rank_time: `Período de tiempo analizado`,
    title: `Analiza el comportamiento de los usuarios y optimiza tu estrategia`,
    today: `Hoy`,
    verified_domain: `Última visita registrada:`,
    week_with_plural: `{weeksCount, plural, =0 {sin semanas} one {# semana}other {# semanas} }`,
  },
  reports_hours_visits: {
    few_visits: `0 a {max}`,
    lot_visits: `+{min}`,
    medium_visits: `{min} a {max}`,
    title: `Días de semana y horas`,
    users: `Páginas vistas:`,
    users_with_email: `Usuarios con email:`,
    users_without_email: `Usuarios sin email:`,
  },
  reports_pageranking: {
    more_results: `Mostrar mas resultados`,
    top_pages: `Páginas más visitadas`,
    total_visits: `Visitas`,
    visits_with_email: `Visitas de usuarios con Email`,
    visits_without_email: `Visitas de usuarios sin Email`,
  },
  reports_partials_campaigns: {
    campaign_name: `Nombre de la  Campaña: `,
    campaign_state: `Estado de la Campaña `,
    campaign_subject: `Asunto: `,
    campaign_summary: `Resumen de la Campaña`,
    delivery_rate: `Tasa de entrega`,
    emails_delivered: `Emails Entregados:`,
    hard_and_soft: `Rebotes Hard y Soft`,
    header_description_shipped: `Tu Campaña ha sido enviada. Estas son las métricas finales de tu envío.`,
    header_description_shipping: `Tu Campaña está en progreso. Estas son las métricas parciales de tu envío.`,
    header_title_shipped: `Reporte de Campaña`,
    header_title_shipping: `Reporte Parcial`,
    last_click_date: `Último Click:`,
    last_open_date: `Última Apertura:`,
    not_open: `No Abiertos`,
    opened: `Abiertos`,
    page_description: `Reporte parcial`,
    page_title: `Doppler | Reporte parcial`,
    shipped: `Enviada`,
    shipping: `Enviando`,
    total_clicks: `Clicks Totales:`,
    total_forwarded: `Cantidad de Reenvíos:`,
    total_openings: `Total de Aperturas:`,
    total_recipients: `Destinatarios totales`,
    total_sent_so_far: `Emails enviados hasta el momento`,
    total_subscribers: `Total de Suscriptores:`,
    total_unsubscribers: `Cantidad de Remociones:`,
    unique_clicks: `Clicks Únicos:`,
    unique_opens: `Aperturas Únicas:`,
  },
  reports_title: `Doppler | Reportes`,
  shopify: {
    admin_apps: `Panel de control de Shopify`,
    admin_apps_url: `https://{shopName}/admin/apps`,
    connect_url: `${urlShopify}/install`,
    entity_buyers: `Compradores`,
    entity_contacts: `Contactos`,
    entity_customers: `Clientes`,
    entity_title: `Nombre de Entidad`,
    error_cannot_access_api: `Ouch! No pudimos conectar con la API de Shopify, por favor vuelve a intentarlo luego.`,
    header_disconnected_warning: `Al presionar "Conectar" serás redirigido a Shopify, donde podrás realizar todos los pasos necesarios para integrar.`,
    header_store: `Nombre de la cuenta:`,
    header_subtitle_MD: `
Envía automáticamente los Contactos de tu tienda y toda su información a Listas de Doppler. Además, podrás importar los productos de tu tienda en
Plantillas de Email y crear Automations de Carrito Abandonado y Producto Visitado. ¿Tienes dudas? Presiona [HELP](${urlHelp}/como-integrar-doppler-y-shopify/).`,
    header_synchronization_date: `Fecha de última sincronización:`,
    header_title: `Conecta Doppler con tu tienda Shopify`,
    list_subtitle: `Puedes sincronizar los datos manualmente cuando gustes.`,
    list_title: `Listas sincronizadas`,
    no_list_available: `Esperando Lista...`,
    table_list: `Nombre de la Lista`,
    table_shopify_customers_count: `Suscriptores`,
    title: `Doppler | Shopify`,
  },
  signup: {
    access_new_account: `Con este email accederás a tu nueva cuenta en <strong>Doppler.</strong>`,
    button_signup: `Crear cuenta gratis`,
    check_email: `Ingresa a `,
    check_email_2: ` para validarlo.`,
    check_your_email: `Revisa tu email`,
    copyright_MD: `© ${year} Doppler LLC. Todos los derechos reservados. [Política de Privacidad y Legales](${urlPrivacyFromSignup}).`,
    copyright_MD_test: `© ${year} Doppler LLC. Todos los derechos reservados.`,
    do_you_already_have_an_account: `¿Ya tienes una cuenta?`,
    email_not_received: `Para ingresar, primero activa tu cuenta desde el email que recibiste de Doppler.`,
    have_doubts: `¿Tienes dudas? <a>Contáctanos</a> y te ayudaremos`,
    head_description: `Atrae, convierte y fideliza clientes con el poder del Email Marketing Automation. ¡Ingresa a Doppler!`,
    head_title: `Email Marketing Automation gratis y con envíos ilimitados | Doppler`,
    if_didnt_receive_email: `Si no recibiste el correo, `,
    image_path: `${signupBannerImagePath}`,
    invitation_signup_title: `Aceptación de invitación`,
    label_email: `Email: `,
    label_firstname: `Nombre: `,
    label_lastname: `Apellido: `,
    label_password: `Contraseña: `,
    label_phone: `Teléfono: `,
    legal_MD: `
Doppler te informa que los datos de carácter personal que nos proporciones al rellenar el presente formulario serán tratados por Doppler LLC como responsable de esta web.

**Finalidad:** Darte de alta en nuestra plataforma y brindarte los servicios que nos requieras..

**Legitimación:** Consentimiento del interesado..

**Destinatarios:** Tus datos serán guardados por Doppler, Zoho como CRM, Google como proveedor del servicio de reCAPTCHA,
Digital Ocean, Cogeco Peer1 y Rackspace como empresas de hosting.

**Información adicional:** En la [Política de Privacidad](${urlPrivacyFromSignup_HTMLEncoded}) de Doppler encontrarás información adicional sobre
la recopilación y el uso de su información personal por parte de Doppler, incluida información sobre acceso, conservación, rectificación,
eliminación, seguridad, transferencias transfronterizas y otros temas.
  `,
    legal_policy: ` Políticas de privacidad y legales.`,
    legal_tittle: `Lee sobre protección de datos en Doppler.`,
    log_in: `Ingresa`,
    no_more_resend_MD: `Ya te reenviamos el email de validación. Revisa si llegó a tu carpeta de spam. ¿Necesitas ayuda?`,
    no_more_resend_MD_link: `Contáctanos.`,
    placeholder_email: `Escribe tu email. Debes tener acceso.`,
    placeholder_first_name: `Escribe tu nombre`,
    placeholder_last_name: `Escribe tu apellido`,
    placeholder_password: `Crea tu contraseña`,
    placeholder_phone: `9 11 2345-6789`,
    privacy_policy_consent_MD: `Acepto la <Link>política de privacidad</Link>.`,
    privacy_policy_consent_url: `${urlPrivacyFromSignup_HTMLEncoded}`,
    promotions_consent: `Quiero recibir novedades y promociones.`,
    resend_email: `solicita el reenvío.`,
    sign_up_sub: `Crea una cuenta gratis y comienza ya una campaña de email para llegar a tus clientes.`,
    thanks_for_registering: `Gracias por registrarte`,
    url_site: `${urlSiteFromSignup}`,
  },
  subscriber: {
    status: {
      active: 'Activo',
      inactive: 'Activo no Asociado a Listas',
      pending: 'Pendiente',
      standBy: 'En Espera de Ampliación de Plan',
      unsubscribed_by_client: 'Removido por el Cliente',
      unsubscribed_by_hard: 'Removido por Rebote Hard',
      unsubscribed_by_never_open: 'Removido por No Aperturas',
      unsubscribed_by_soft: 'Removido por Rebote Soft',
      unsubscribed_by_subscriber: 'Removido por el Suscriptor',
    },
  },
  subscriber_gdpr: {
    consent: 'Consentimiento:',
    description: 'Aquí encontrarás el historial de todos los consentimientos dados por tu Suscriptor.',
    download_permission_history: 'Descargar historial completo',
    empty_data: 'Este Suscriptor no ha aceptado ni rechazado ningun permiso.',
    empty_html_text: 'Sin texto legal definido',
    latest_results: 'Últimos 10 resultados',
    modification_date: 'Fecha de modificación:',
    modification_source: 'Origen:',
    modification_source_ip: 'IP origen de modificación:',
    permission_description: 'Texto personalizado',
    permission_name: 'Nombre del campo',
    permission_value: 'Valor',
    title: 'Historial de permisos RGPD del Suscriptor',
    value_false: 'Rechazado',
    value_none: 'Sin respuesta',
    value_true: 'Aceptado',
  },
  subscriber_history: {
    alt_image: 'Preview de la Campaña',
    delivery_status: {
      hardBounced: 'Rebotado Hard',
      notOpened: 'No Abierto',
      opened: 'Abierto',
      softBounced: 'Rebotado Soft',
    },
    description: 'Aquí podrás conocer el historial de comportamiento en Campañas de tus Suscriptores.',
    empty_data: 'Hasta el momento no hay Campañas enviadas',
    subscriber_breadcrumb: 'Suscriptores',
    subscriber_breadcrumb_url: `${urlMasterSubscriber}`,
    table_result: {
      aria_label_table: `Resultado de Historial de Campañas`,
    },
    title: 'Comportamiento histórico del Suscriptor',
    unsubscribed_date: 'Fecha de Remoción:',
  },
  subscriber_list_selector: {
    confirm_selection: 'Confirmar Selección',
    error_loading_list: 'Error al mostrar las Listas. Cierra esta ventana y vuelve a abrirla desde el botón "Seleccionar Listas".',
    max_limit_exceeded: `Ya seleccionaste el número máximo de listas permitidas.`,
    no_list: {
      create_list: 'Crear una lista',
      create_list_url: `${urlCreateSubscriberList}`,
      description_MD: `
Contar con una **Base de Datos actualizada** es clave para la efectividad del Email & Automation Marketing.
Con Doppler puedes generar Segmentos con intereses o características comunes, crear Formularios para atraer nuevos Contactos y más.`,
      not_now: 'Ahora no',
      strong_text: '¡Crea tu primera Lista e importa tus Contactos en simples pasos!',
      title: '¡Aún no tienes Listas creadas!',
    },

    table_columns: {
      list_name: 'Nombre de la lista',
      subscribers: 'Suscriptores',
    },
  },
  trafficSources: {
    direct: `Directo`,
    email: `Email`,
    organic: `Búsqueda Orgánica`,
    paid: `Publicidad en Buscadores`,
    referral: `Referencia`,
    social: `Redes Sociales`,
    title: `Fuentes de trafico`,
    users_with_email: `Usuarios con email`,
    users_without_email: `Usuarios sin email`,
  },
  updatePaymentInformationSuccess: {
    all_invoices_processed_message: 'Ya reactivamos tu cuenta de Doppler.',
    all_invoices_processed_title: '¡Listo! Tu pago fue aceptado.',
    all_invoices_processed_with_active_account_message: 'Puedes continuar utilizando tu cuenta de Doppler.',
    back_button: 'Volver',
    credit_card_error: {
      card_volume_exceeded: 'Tu tarjeta superó el límite diario de intentos.',
      declined: 'El pago fue rechazado. Comunícate con el emisor de tu tarjeta.',
      default: 'No pudimos procesar tu pago. Inténtalo más tarde.',
      doNotHonor: 'El pago fue rechazado. Comunícate con el emisor de tu tarjeta.',
      insufficient_funds: 'La tarjeta no tiene fondos suficientes para este pago. Prueba con otra tarjeta.',
      invalid_credit_card_number: 'Los datos de la tarjeta son incorrectos. Prueba una vez más o usa otra tarjeta',
      invalid_expiration_date: 'Los datos de la tarjeta son incorrectos. Prueba una vez más o usa otra tarjeta.',
      pending: 'Pendiente',
      suspected_fraud: 'El pago fue rechazado. Comunícate con el emisor de tu tarjeta.',
    },
    enter_button: 'Ingresar',
    go_to_login_button: 'Ingresar',
    not_all_invoices_processed_legend: 'Reactivamos tu cuenta de Doppler para que puedas hacer el pago.',
    not_all_invoices_processed_message: 'Hubo al menos un pago pendiente rechazado. Te contactaremos a {userEmail} para solucionarlo.',
    not_all_invoices_processed_title: 'Aún quedan pagos pendientes',
    payment_pending_message_line1: 'Confirmaremos tu pago en menos de 48 horas hábiles y te avisaremos a {userEmail}.',
    payment_pending_message_line2: 'Reactivamos tu cuenta para que puedas usar Doppler mientras esperamos que se confirme tu pago.',
    payment_pending_title: 'Tu pago con Mercado Pago está en proceso',
    rejected_payments_legend_1: `Prueba otra vez con una tarjeta internacional de <bold>Visa</bold>, <bold>American Express</bold> o <bold>Mastercard</bold>.`,
    rejected_payments_legend_2: `Si necesitas ayuda, [contáctanos](${mailtoSupport}).`,
    rejected_payments_message: ' Estos pagos pendientes fueron rechazados:',
    rejected_payments_title: 'Tu pago fue rechazado',
    title: 'Reproceso finalizado',
    transfer_message: 'En breve recibirás la factura para saldar tu deuda.',
    transfer_title: '¡Listo! Tu método de pago ha sido actualizado.',
  },
  updatePaymentMethod: {
    button_back: 'Volver',
    payment_method: {
      save_continue_button: 'Guardar y pagar',
      success_message: '¡Operación procesada con éxito!',
      title: 'Método de Pago',
      transfer: {
        email: 'Email',
        firstname: 'Nombre',
        lastname: 'Apellido',
        note_legend: `Por favor, confirma tu información de contacto para que te enviemos el detalle de tus facturas pendientes y la cuenta bancaria donde hacer las transferencias.
        Te contactaremos para ayudarte a resolver tus pagos pendientes.`,
        phone: 'Teléfono',
        send_button: 'Enviar mi información',
        send_email_error_message: '¡Ouch! No se ha podido enviar el email.',
        send_email_success_message: '¡El email ha sido enviado con éxito!',
        title: 'Necesitamos contactarte',
      },
    },
    reprocess: {
      invoices_declined_table: {
        amount_column: 'Valor',
        date_column: 'Fecha de facturación',
        error_column: 'Motivo que impidió el pago',
        invoice_column: 'Número de factura',
      },
      payment_now_button: 'Pagar todo',
      pending_amount_message: 'Total',
      success_message: '¡Operación procesada con éxito!',
      title: 'Pagos pendientes',
    },
    title: `Ingresa un nuevo método de pago para cancelar tus facturas pendientes.`,
  },
  upgradePlanForm: {
    confirmation_subtitle_contact: `Acabas de contratar un nuevo Plan Mensual para enviar Campañas ilimitadas hasta <bold>Contactos.</bold> Te enviaremos un email con el detalle del nuevo Plan.`,
    confirmation_subtitle_shipping: `Acabas de contratar un nuevo Plan Mensual para enviar Campañas ilimitadas hasta <bold>Envíos.</bold> Te enviaremos un email con el detalle del nuevo Plan.`,
    confirmation_title: `¡Hecho!`,
    message_placeholder: `Tu mensaje`,
    plan_select: `Selecciona el Plan`,
    title: `Solicita una actualización de tu Plan`,
    update: `Actualizar Plan`,
  },
  upgrade_suggestion_form: {
    breadcrumb_increase_plan: 'Aumentar Plan',
    breadcrumb_maximum_plan: 'Máximo de Plan',
    description: 'Nuestro equipo comercial te asesorará acerca del Plan más conveniente de acuerdo a tus necesidades.',
    form_hour_contact: '¿En qué horario podemos contactarte?',
    form_legend: 'Completa el siguiente formulario',
    form_title: 'Completa el Formulario y nos contactaremos pronto',
    message: 'Selecciona los servicios adicionales de tu interés',
    message_placeholder: 'Escribe aquí tu mensaje',
    meta_description: 'Formulario para sugerir upgrade de plan.',
    submit_button: 'Enviar',
    success: '¡Excelente! Nos pondremos en contacto a la brevedad',
    title: 'Solicita un Plan mayor',
  },
  validate_max_subscribers_form: {
    button_accept: 'Aceptar',
    checkbox_empty: '¡Ouch! Tienes que seleccionar al menos una opción.',
    form_help: '* ¿Quieres saber porqué te solicitamos esta información?',
    form_help_link_text: 'Entérate aquí',
    info_text: '** Este proceso puede presentar demoras ya que debemos analizar los datos en profundidad. Gracias por tu paciencia.',
    request_processed: '¡Perfecto! Estamos validando el origen de tus Suscriptores. Por favor, recuerda que este proceso puede demorar.',
    subtitle: `Por favor, bríndanos tus datos de contacto para esta acción. Nos comunicaremos a la brevedad.Completa el siguiente
      Formulario para que podamos validar el origen de tus Suscriptores. Sólo llevará un minuto. Muchas gracias.`,
    title: 'Valida el origen de tus Suscriptores',
  },
  validation_messages: {
    error_account_contact_zoho_chat: `<button>Chatea con el equipo de Atención al Cliente</button> para solucionarlo.`,
    error_account_has_not_users_associated: `¡Ouch! El email que has ingresado no tiene ninguna cuenta asociada, deberás comunicarte con el administrador de la cuenta.`,
    error_account_is_blocked_disabled_by_cm: `Esta cuenta fue bloqueada por el administrador. Contáctate para más información: `,
    error_account_is_blocked_invalid_password: `¡Ouch! Esta cuenta ha sido bloqueada debido a intentos de acceso fallidos.`,
    error_account_is_blocked_invalid_password_contact_support_MD: `Por favor [contacta al equipo de Atención al Cliente](${mailtoAdmin + subjectBlockedAccountInvalidPassword}) para solucionarlo.`,
    error_account_is_blocked_invalid_password_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta bloqueada por intentos fallidos?`,
    error_account_is_blocked_not_pay: `La cuenta está suspendida porque existen pagos pendientes.`,
    error_account_is_blocked_not_pay_contact_support_MD: `Por favor [contacta al equipo de Atención al Cliente](${mailtoAdmin + subjectBlockedAccountNoPay}) para solucionarlo.`,
    error_account_is_blocked_not_pay_update_payment_information: `Revisar pagos pendientes`,
    error_account_is_blocked_not_pay_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta suspendida por falta de pago?`,
    error_account_is_canceled_not_pay: `¡Ouch! Esta cuenta ha sido cancelada por falta de pago.`,
    error_account_is_canceled_not_pay_contact_support_MD: `Por favor [contacta al equipo de Atención al Cliente](${mailtoAdmin + subjectCanceledAccountNoPay}) para solucionarlo.`,
    error_account_is_canceled_not_pay_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta cancelada por falta de pago?`,
    error_account_is_canceled_other_reason: `¡Ouch! Esta cuenta ha sido cancelada.`,
    error_account_is_canceled_other_reason_contact_support_MD: `Por favor [contacta al equipo de Atención al Cliente](${mailtoAdmin + subjectCanceledAccountOtherReason}) para solucionarlo.`,
    error_account_is_canceled_other_reason_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta cancelada?`,
    error_checkbox_policy: `¡Ouch! No has aceptado la Política de Privacidad de Doppler.`,
    error_email_already_exists: `¡Ouch! Ya posees una cuenta en Doppler.`,
    error_expired_invitation_link: `¡Aviso Importante! La invitación como colaborador ha expirado o ha sido inhabilitada, deberás contactarte con el administrador.`,
    error_has_accents: `¡Ouch! El Email no debe contener tildes ni acentos.`,
    error_invalid_captcha: `¡Ouch! No pudimos validar que seas humano, por favor refresca la pantalla e intenta nuevamente.`,
    error_invalid_card_number: '¡Ouch! Número inválido. Por favor, revisa que esté escrito correctamente.',
    error_invalid_cbu: `Ouch! El formato del CBU es incorrecto`,
    error_invalid_collaborator_email: `¡Ouch! No puedes invitarte a ti mismo.`,
    error_invalid_cuit: `¡Ouch! El formato del Cuit es incorrecto`,
    error_invalid_dni: `Ouch! El formato del DNI es incorrecto`,
    error_invalid_domain: `¡Ouch! Algo salió mal. Por favor revisa que tu Email sea correcto o intenta con otro.`,
    error_invalid_domain_to_register_account: `¡Ouch! Email inválido para crear una cuenta.`,
    error_invalid_email_address: `Ingresa un email válido`,
    error_invalid_expiry_date: `Ingresa una fecha de expiración válida`,
    error_invalid_login: `El usuario o la contraseña son incorrectos. Intenta de nuevo.`,
    error_invalid_name: `¡Ouch! Escribe usando solo letras y no números.`,
    error_invalid_nit: `Ouch! El formato del NIT es incorrecto`,
    error_invalid_rfc: `Ouch! El formato del RFC es incorrecto`,
    error_invalid_size_file: `El archivo debe ser PDF, menor a {maxSizeMB} MB`,
    error_invalid_type_pdf: `El tipo de archivo no es un PDF`,
    error_is_withholding_agent: 'Si sos agente de retención no podes usar débito automático',
    error_min_length: `¡Ouch! Minimo de caracteres invalido.`,
    error_min_length_2: `¡Ouch! Escribe al menos dos caracteres.`,
    error_password_character_length: `8 caracteres mínimo`,
    error_password_digit: `Un número`,
    error_password_format: `Recuerda que debe contener 8 caracteres mínimo, una letra y un número.`,
    error_password_invalid: `¡Ouch! La contraseña es incorrecta.`,
    error_password_letter: `Una letra`,
    error_password_match: `¡Ouch! Las contraseñas no coinciden.`,
    error_password_missing: `¡Ouch! Ingresa una nueva contraseña.`,
    error_password_safe: `¡Tu Contraseña es segura!`,
    error_phone_invalid: `Ingresa un teléfono válido`,
    error_phone_invalid_country: `¡Ouch! El código de país no es válido.`,
    error_phone_required: `Ingresa un teléfono válido`,
    error_phone_too_long: `¡Ouch! El número de teléfono es demasiado largo.`,
    error_phone_too_short: `¡Ouch! El número de teléfono es demasiado corto.`,
    error_register_denied: `¡Alto ahí! Has alcanzado el límite de cuentas permitido.`,
    error_required_field: `¡Ouch! El campo está vacío.`,
    error_unexpected_MD: `¡Ouch! Detectamos un problema de conexión. Por favor inténtalo nuevamente en unos minutos.`,
    error_unexpected_register_MD: `¡Ouch! Algo salió mal. Por favor, vuelve a intentarlo más tarde o [contacta a nuestro equipo de Soporte](${mailtoSupport}).`,
    error_upload_file: `¡Ouch! Algo salió mal al cargar el Archivo. Inténtelo nuevamente.`,
    error_wrong_invitation_link: `¡Aviso Importante! No tienes invitaciones pendientes.`,
    warning_ip_validation_notification: `Estás intentando ingresar desde un dispositivo que Doppler no reconoce. ¡Revisa tu casilla! Encontrarás un Email con los pasos a seguir.`,
    warning_ip_validation_notification_SignUp: `Doppler no reconoce este dispositivo. Primero prueba iniciando sesión en la cuenta!`,
    warning_user_access_denied: `¡Ouch! Tu inicio de sesión ha sido rechazado.`,
  },
};

export default messages_es;
